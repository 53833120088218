/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { SagaIterator } from '@redux-saga/types';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  actions,
  addNote,
  setData,
  setTradesOptions,
  Types,
  updatePotentialDuplicate,
} from '../duck';
import { APIError, errorHandler } from 'services/app';
import {
  IActionConfirmDuplicate,
  IActionDeclineDuplicate,
  IActionGetPoteltialDuplicates,
  IActionSubmitNote,
} from '../models';
import {
  ConfirmDuplicateResponse,
  confirmDuplicateService,
  DeclineDuplicateResponse,
  declineDuplicateService,
  GetPotentialDuplicatesResponse,
  getPotentialDuplicatesService,
  GetTradeOptionsResponse,
  getTradeOptionsService,
  SubmitNoteResponse,
  submitNoteService,
} from 'services/potentialDuplicates';

const { failure, fulfill, request } = actions;

/**
 * Saga to request the potential duplicate workorders
 */
export function* getPotentialDuplicatesSaga(
  action: IActionGetPoteltialDuplicates,
) {
  yield put(request());
  try {
    const potentialDuplicates: GetPotentialDuplicatesResponse['data'] =
      yield call(getPotentialDuplicatesService, action.payload);
    if (potentialDuplicates.results) {
      yield put(setData(potentialDuplicates.results));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill());
  }
}

/**
 * Saga to request trade options
 */
export function* getTradeOptionsSaga() {
  yield put(request());
  try {
    const trades: GetTradeOptionsResponse['data'] = yield call(
      getTradeOptionsService,
    );
    yield put(setTradesOptions(trades));
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill());
  }
}

/**
 * Saga to submit a note to the potential duplicate workorders
 */
export function* submitNoteSaga(action: IActionSubmitNote) {
  yield put(request());
  try {
    const newNote: SubmitNoteResponse = yield call(
      submitNoteService,
      action.payload,
    );
    if (newNote && newNote.data.potential_duplicate) {
      yield put(
        addNote({ id: newNote.data.potential_duplicate, note: newNote.data }),
      );
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill());
  }
}

/**
 * Saga to confirm a potential duplicate workorder
 */
export function* confirmDuplicateSaga(action: IActionConfirmDuplicate) {
  yield put(request());
  try {
    const response: ConfirmDuplicateResponse = yield call(
      confirmDuplicateService,
      action.payload,
    );
    if (response.data) {
      yield put(
        updatePotentialDuplicate({
          id: action.payload.id,
          data: response.data,
        }),
      );
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill());
  }
}

/**
 * Saga to decline a potential duplicate workorder
 */
export function* declineDuplicateSaga(action: IActionDeclineDuplicate) {
  yield put(request());
  try {
    const response: DeclineDuplicateResponse = yield call(
      declineDuplicateService,
      action.payload,
    );
    if (response.data) {
      yield put(
        updatePotentialDuplicate({
          id: action.payload.id,
          data: response.data,
        }),
      );
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill());
  }
}

export default function* appSagas(): SagaIterator {
  yield all([
    takeLatest(Types.GET_POTENTIAL_DUPLICATES, getPotentialDuplicatesSaga),
    takeLatest(Types.GET_TRADES_OPTIONS, getTradeOptionsSaga),
    takeLatest(Types.SUBMIT_NOTE, submitNoteSaga),
    takeLatest(Types.CONFIRM_DUPLICATE, confirmDuplicateSaga),
    takeLatest(Types.DECLINE_DUPLICATE, declineDuplicateSaga),
  ]);
}
