import React, { FC, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState } from 'storage/auth/models';
import { Stack, Card, CardContent, CardActions, Button, IconButton, Typography, CircularProgress, Alert } from '@mui/material';
import LoginBackground from '../../assets/login-bg.jpg';
import BattleshipLogo from '../../assets/battleship-logo-page.svg';
import ArrowBack from '@mui/icons-material/ArrowBack';
import theme from 'theme';
// import { resetPassword } from 'storage/auth/duck';
import { IAppState } from 'storage/app/models';
import ByMakepath from 'components/Map/ByMakepath';


const PasswordReset: FC = () => {
  // const dispatch = useDispatch();
  const metadata = useSelector<IStore, IAppState['metadata']>((state) => state.app.metadata);
  const error = useSelector<IStore, IAuthState['error']>((state) => state.auth.error);
  const loading = useSelector<IStore, IAuthState['loading']>((state) => state.auth.loading);
  // const [emailSent, setEmailSent] = useState(false)
  // const [resetPasswordTimeout, setResetPasswordTimeout] = useState(0)
  // const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const errorTimeoutRef = useRef<number | undefined>(undefined)

  /*
  * Handle error message timeout
  */
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      if (errorTimeoutRef.current) {
        window.clearTimeout(errorTimeoutRef.current);
        errorTimeoutRef.current = undefined;
      }
      errorTimeoutRef.current = window.setTimeout(() => {
        setErrorMessage(undefined);
        errorTimeoutRef.current = undefined;
      }, 5000);
    }
  }, [error, loading])

  /*
  * Refresh state if reset password email was sent
  */
  /*useEffect(() => {
    if (emailSent && !error && !loading) {
      let timeout = 0;
      const interval = window.setInterval(() => {
        timeout += 0.23
        setResetPasswordTimeout(timeout)
      }, 10)
      setEmail("");
      window.setTimeout(() => {
        window.clearInterval(interval);
        setResetPasswordTimeout(0);
        setEmailSent(false);
      }, 5000);
    }
  }, [emailSent, error, loading])*/

  /*const handleSendConfirmationEmail = () => {
    if (email) {
      setEmailSent(true)
      dispatch(resetPassword(email));
    }
  }*/

  return (
    <Stack
      className="homepage"
      component="section"
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <Stack
        direction="row"
        component="main"
        flex={1}
        sx={{ overflow: 'hidden', position: 'relative' }}
      >
        <div style={{ position: 'absolute', zIndex: -1, width: '100%', height: '100%', opacity: 1, backgroundImage: `url(${LoginBackground})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
        <Stack justifyContent={"center"} alignItems={"center"} flex={1}>
          <Link to="/login/">
            <IconButton sx={{ position: 'fixed', top: '1rem', left: '1rem', color: theme.palette.text.primary }}>
              <ArrowBack />
            </IconButton>
          </Link>
          {/*emailSent && !loading && !error ?
            <Card sx={{
              padding: "2rem",
              backgroundColor: theme.palette.login.paper,
              borderStyle: 'solid',
              borderBottomWidth: '2px',
              borderImage: `linear-gradient(to right, ${theme.palette.text.primary}, ${theme.palette.text.primary} ${resetPasswordTimeout}%, transparent ${resetPasswordTimeout}%, transparent) 0 1 100%`
            }}>
              <CardContent>
                <Stack gap={2}>
                  <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem" }}>
                    We sent you an email with a link to reset your password.
                  </Typography>
                </Stack>
              </CardContent>
            </Card> :
            <Card sx={{ padding: "2rem", backgroundColor: theme.palette.login.paper }}>
              <CardContent>
                <Stack gap={2}>
                  <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem", marginBottom: "1rem" }}>
                    It&apos;s okay, it happens to the best of us.
                  </Typography>
                  <TextField
                    label="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onSubmit={handleSendConfirmationEmail}
                    disabled={loading}
                    autoFocus
                  />
                </Stack>
              </CardContent>
              <CardActions>
                <Button variant="contained" sx={{ margin: 'auto' }} onClick={handleSendConfirmationEmail}>
                  Reset Password {loading &&
                    <CircularProgress sx={{
                      color: theme.palette.background.default,
                      height: "1rem !important",
                      width: "1rem !important",
                      marginLeft: "1rem"
                    }} />
                  }
                </Button>
              </CardActions>
                </Card>*/
          }
          < Card sx={{ maxWidth: '60rem', width: '90%', padding: "2rem", backgroundColor: theme.palette.login.paper }}>
            <CardContent>
              <Stack gap={2}>
                <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem", marginBottom: "1rem" }}>
                  In case you can&apos;t log in to Battleship anymore, please contact the team member who set you up with access to Battleship or send an email to battleship@makepath.com.
                </Typography>
              </Stack>
            </CardContent>
            <CardActions>
              <Link to="/login/" style={{ margin: 'auto' }} >
                <Button variant="contained">
                  Ok {loading &&
                    <CircularProgress sx={{
                      color: theme.palette.background.default,
                      height: "1rem !important",
                      width: "1rem !important",
                      marginLeft: "1rem"
                    }} />
                  }
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Stack>
        <Stack flexDirection="row" alignItems="flex-end" sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}>
          <ByMakepath boxStyles={{ padding: 0, backgroundColor: 'transparent', marginRight: '1rem' }} />
          {metadata.version &&
            <Typography  >{metadata.version === 'latest' ? metadata.version : `v${metadata.version}`}</Typography>
          }
        </Stack>
        <img src={BattleshipLogo} style={{ position: 'absolute', top: "12.5%", right: "50%", transform: "translate(50%, -25%)" }} alt="Battleship" />
        {errorMessage &&
          <Alert
            variant="filled"
            severity='error'
            sx={{ position: 'absolute', bottom: "1rem", left: "50%", transform: "translate(-50%)" }}
            onClick={() => setErrorMessage(undefined)}
          >
            {errorMessage}
          </Alert>
        }
      </Stack>
    </Stack >
  );
};

export default PasswordReset;
