import React, { FC, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState } from 'storage/auth/models';
import { Stack, Card, CardContent, CardActions, Button, IconButton, TextField, Typography, CircularProgress, Alert } from '@mui/material';
import LoginBackground from '../../assets/login-bg.jpg';
import BattleshipLogo from '../../assets/battleship-logo-page.svg';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { resetPasswordConfirm } from 'storage/auth/duck';
import theme from 'theme';
import { IAppState } from 'storage/app/models';
import ByMakepath from 'components/Map/ByMakepath';


const PasswordResetConfirm: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const metadata = useSelector<IStore, IAppState['metadata']>((state) => state.app.metadata);
  const error = useSelector<IStore, IAuthState['error']>((state) => state.auth.error);
  const loading = useSelector<IStore, IAuthState['loading']>((state) => state.auth.loading);
  const [passwordChangeRequested, setPasswordChangeRequested] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [resetPasswordTimeout, setResetPasswordTimeout] = useState(0);
  const [confirmToken, setConfirmToken] = useState<string | undefined>(undefined);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const errorTimeoutRef = useRef<number | undefined>(undefined)

  /*
  * Get confirm token from URL parameters
  */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      setConfirmToken(token);
    }
  }, [])

  /*
  * Get confirm token from URL parameters
  */
  useEffect(() => {
    if (!loading && !error && passwordChangeRequested) {
      setPasswordChanged(true)
    } else {
      setPasswordChanged(false)
    }
  }, [loading, passwordChangeRequested])

  /*
  * Handle error message timeout
  */
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      if (errorTimeoutRef.current) {
        window.clearTimeout(errorTimeoutRef.current);
        errorTimeoutRef.current = undefined;
      }
      errorTimeoutRef.current = window.setTimeout(() => {
        setErrorMessage(undefined);
        setPasswordChangeRequested(false);
        errorTimeoutRef.current = undefined;
      }, 5000);
    }

  }, [error])

  useEffect(() => {
    if (errorMessage && !error) {
      if (errorTimeoutRef.current) {
        window.clearTimeout(errorTimeoutRef.current);
        errorTimeoutRef.current = undefined;
      }
      errorTimeoutRef.current = window.setTimeout(() => {
        setErrorMessage(undefined);
        setPasswordChangeRequested(false);
        errorTimeoutRef.current = undefined;
      }, 5000);
    }

  }, [errorMessage])

  /*
  * Refresh state if reset password email was sent
  */
  useEffect(() => {
    if (passwordChanged) {
      let timeout = 0;
      const interval = window.setInterval(() => {
        timeout += 0.23
        setResetPasswordTimeout(timeout)
      }, 10)
      setNewPassword("");
      setNewPasswordConfirm("");
      window.setTimeout(() => {
        window.clearInterval(interval);
        setResetPasswordTimeout(0)
        setPasswordChanged(false)
        setPasswordChangeRequested(false);
        history.push('/login/')
      }, 5000);
    }
  }, [passwordChanged])

  const handleChangePassword = () => {
    if (!confirmToken) {
      setErrorMessage("Invalid token. Please try re-sending the password reset email");
    } else if (!newPassword || !newPasswordConfirm) {
      setErrorMessage("Please fill in all fields");
    } else if (newPassword !== newPasswordConfirm) {
      setErrorMessage("Passwords do not match");
    } else if (newPassword.length < 8 && newPasswordConfirm.length < 8) {
      setErrorMessage("Password must be at least 8 characters long");
    } else {
      dispatch(resetPasswordConfirm({ password: newPassword, token: confirmToken }));
      setPasswordChangeRequested(true);
    }
  }

  return (
    <Stack
      className="homepage"
      component="section"
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <Stack
        direction="row"
        component="main"
        flex={1}
        sx={{ overflow: 'hidden', position: 'relative' }}
      >
        <div style={{ position: 'absolute', zIndex: -1, width: '100%', height: '100%', opacity: 1, backgroundImage: `url(${LoginBackground})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
        <Stack justifyContent={"center"} alignItems={"center"} flex={1}>
          <Link to="/login/">
            <IconButton sx={{ position: 'fixed', top: '1rem', left: '1rem', color: theme.palette.text.primary }}>
              <ArrowBack />
            </IconButton>
          </Link>
          {confirmToken ?
            passwordChanged && !loading && !error ?
              <Card sx={{
                padding: "2rem",
                borderStyle: 'solid',
                borderBottomWidth: '2px',
                borderImage: `linear-gradient(to right, ${theme.palette.text.primary}, ${theme.palette.text.primary} ${resetPasswordTimeout}%, transparent ${resetPasswordTimeout}%, transparent) 0 1 100%`
              }}>
                <CardContent>
                  <Stack gap={2}>
                    <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem" }}>
                      All set! You&apos;re being redirected to the login page...
                    </Typography>
                  </Stack>
                </CardContent>
              </Card> :
              <Card sx={{ padding: "2rem", backgroundColor: theme.palette.login.paper }}>
                <CardContent>
                  <Stack gap={2}>
                    <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem", marginBottom: "1rem" }}>
                      Enter new password
                    </Typography>
                    <TextField
                      label="New password"
                      value={newPassword}
                      type="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      onSubmit={handleChangePassword}
                      disabled={loading}
                      autoFocus
                    />
                    <TextField
                      label="Confirm new password"
                      value={newPasswordConfirm}
                      type="password"
                      onChange={(e) => setNewPasswordConfirm(e.target.value)}
                      onSubmit={handleChangePassword}
                      disabled={loading}
                    />
                  </Stack>
                </CardContent>
                <CardActions>
                  <Button variant="contained" sx={{ margin: 'auto' }} onClick={handleChangePassword}>
                    Reset Password {loading &&
                      <CircularProgress sx={{
                        color: theme.palette.background.default,
                        height: "1rem !important",
                        width: "1rem !important",
                        marginLeft: "1rem"
                      }} />
                    }
                  </Button>
                </CardActions>
              </Card> :
            <Card sx={{ padding: "2rem" }}>
              <CardContent>
                <Stack gap={2}>
                  <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem" }}>
                    It looks like you don&apos;t have a valid token. <br />
                    Please try re-sending the password reset email.
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          }
        </Stack>
        <Stack flexDirection="row" alignItems="flex-end" sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}>
          <ByMakepath boxStyles={{ padding: 0, backgroundColor: 'transparent', marginRight: '1rem' }} />
          {metadata.version &&
            <Typography  >{metadata.version === 'latest' ? metadata.version : `v${metadata.version}`}</Typography>
          }
        </Stack>
        <img src={BattleshipLogo} style={{ position: 'absolute', top: "12.5%", right: "50%", transform: "translate(50%, -25%)" }} alt="Battleship" />
        {errorMessage &&
          <Alert
            variant="filled"
            severity='error'
            sx={{ position: 'absolute', bottom: "1rem", left: "50%", transform: "translate(-50%)" }}
            onClick={() => setErrorMessage(undefined)}
          >
            {errorMessage}
          </Alert>
        }
      </Stack>
    </Stack>
  );
};

export default PasswordResetConfirm;
