// Libraries
import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

// Custom
import appSagas from 'storage/app/saga';
import authSagas from 'storage/auth/saga';
import notesSagas from 'storage/notes/saga';
import alertsSagas from 'storage/alerts/saga';
import trackingSagas from 'storage/tracking/saga';
import potentialDuplicatesSagas from 'storage/potentialDuplicates/saga';

export default function* rootSaga(): SagaIterator {
  yield all([
    fork(appSagas),
    fork(authSagas),
    fork(notesSagas),
    fork(alertsSagas),
    fork(trackingSagas),
    fork(potentialDuplicatesSagas),
  ]);
}
