/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAction } from 'lib/redux/models';
import {
  EPotentialDuplicatesActionTypes,
  IActionAddNote,
  IActionConfirmDuplicate,
  IActionDeclineDuplicate,
  IActionGetPoteltialDuplicates,
  IActionSetData,
  IActionSetFilter,
  IActionSetTradesOptions,
  IActionSubmitNote,
  IActionToggleEscalated,
  IActionToggleWorkingOnIt,
  IActionUpdatePotentialDuplicate,
  IPotentialDuplicatesState,
} from '../models';

// ACTION TYPES
export const Types = {
  FAILURE: EPotentialDuplicatesActionTypes.FAILURE,
  WARNING: EPotentialDuplicatesActionTypes.WARNING,
  FULFILL: EPotentialDuplicatesActionTypes.FULFILL,
  REQUEST: EPotentialDuplicatesActionTypes.REQUEST,
  GET_POTENTIAL_DUPLICATES:
    EPotentialDuplicatesActionTypes.GET_POTENTIAL_DUPLICATES,
  SET_DATA: EPotentialDuplicatesActionTypes.SET_DATA,
  GET_TRADES_OPTIONS: EPotentialDuplicatesActionTypes.GET_TRADES_OPTIONS,
  SET_TRADES_OPTIONS: EPotentialDuplicatesActionTypes.SET_TRADES_OPTIONS,
  SET_FILTER: EPotentialDuplicatesActionTypes.SET_FILTER,
  UPDATE_POTENTIAL_DUPLICATE:
    EPotentialDuplicatesActionTypes.UPDATE_POTENTIAL_DUPLICATE,
  TOGGLE_WORKSPACE: EPotentialDuplicatesActionTypes.TOGGLE_WORKSPACE,
  TOGGLE_WORKING_ON_IT: EPotentialDuplicatesActionTypes.TOGGLE_WORKING_ON_IT,
  TOGGLE_ESCALATED: EPotentialDuplicatesActionTypes.TOGGLE_ESCALATED,
  SUBMIT_NOTE: EPotentialDuplicatesActionTypes.SUBMIT_NOTE,
  ADD_NOTE: EPotentialDuplicatesActionTypes.ADD_NOTE,
  CONFIRM_DUPLICATE: EPotentialDuplicatesActionTypes.CONFIRM_DUPLICATE,
  DECLINE_DUPLICATE: EPotentialDuplicatesActionTypes.DECLINE_DUPLICATE,
};
export const initialState: IPotentialDuplicatesState = {
  page: 1,
  potentialDuplicates: [],
  isWorkspaceOpen: false,
  filters: {
    declined: false,
    mentionsDuplicate: false,
    isDuplicate: false,
  },
  filtersOptions: {
    trades: [],
  },
};

// REDUCER
export default (
  state: IPotentialDuplicatesState = initialState,
  action?: IAction,
): IPotentialDuplicatesState => {
  switch (action?.type) {
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case Types.WARNING:
      return {
        ...state,
        warning: action.payload,
      };
    case Types.FULFILL: {
      const loadingKey = action.payload || 'loading';
      return {
        ...state,
        [loadingKey]: false,
      };
    }
    case Types.REQUEST: {
      const loadingKey = action.payload || 'loading';
      return {
        ...state,
        [loadingKey]: true,
        error: undefined,
        warning: undefined,
      };
    }
    case Types.SET_DATA:
      return {
        ...state,
        potentialDuplicates: action.payload,
      };
    case Types.SET_TRADES_OPTIONS:
      return {
        ...state,
        filtersOptions: {
          ...state.filtersOptions,
          trades: action.payload.map((trade: string) => ({
            name: trade,
            value: trade,
          })),
        },
      };
    case Types.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        },
      };
    case Types.UPDATE_POTENTIAL_DUPLICATE:
      return {
        ...state,
        potentialDuplicates: state.potentialDuplicates.map(
          (potentialDuplicate) => {
            if (potentialDuplicate.id === action.payload.id) {
              return {
                ...potentialDuplicate,
                ...action.payload.data,
              };
            } else {
              return potentialDuplicate;
            }
          },
        ),
      };
    case Types.TOGGLE_WORKSPACE:
      return {
        ...state,
        isWorkspaceOpen: !state.isWorkspaceOpen,
      };
    case Types.TOGGLE_WORKING_ON_IT:
      return {
        ...state,
        potentialDuplicates: state.potentialDuplicates.map(
          (potentialDuplicate) => {
            if (
              potentialDuplicate.work_order_a.id === action.payload.workorderId
            ) {
              return {
                ...potentialDuplicate,
                work_order_a: {
                  ...potentialDuplicate.work_order_a,
                  ...(potentialDuplicate.work_order_a.working_on_it
                    ? {
                        working_on_it_user: undefined,
                        is_escalated: false,
                        escalated_user: undefined,
                      }
                    : {
                        working_on_it_user: action.payload.user.email,
                      }),
                  working_on_it: !potentialDuplicate.work_order_a.working_on_it,
                },
              };
            } else if (
              potentialDuplicate.work_order_b.id === action.payload.workorderId
            ) {
              return {
                ...potentialDuplicate,
                work_order_b: {
                  ...potentialDuplicate.work_order_b,
                  ...(potentialDuplicate.work_order_b.working_on_it
                    ? {
                        working_on_it_user: undefined,
                        is_escalated: false,
                        escalated_user: undefined,
                      }
                    : {
                        working_on_it_user: action.payload.user.email,
                      }),
                  working_on_it: !potentialDuplicate.work_order_b.working_on_it,
                },
              };
            }
            return potentialDuplicate;
          },
        ),
      };
    case Types.TOGGLE_ESCALATED:
      return {
        ...state,
        potentialDuplicates: state.potentialDuplicates.map(
          (potentialDuplicate) => {
            if (
              potentialDuplicate.work_order_a.id === action.payload.workorderId
            ) {
              return {
                ...potentialDuplicate,
                work_order_a: {
                  ...potentialDuplicate.work_order_a,
                  ...(!potentialDuplicate.work_order_a.is_escalated
                    ? {
                        working_on_it_user: action.payload.user.email,
                        working_on_it: true,
                        escalated_user: action.payload.user.email,
                      }
                    : {
                        is_escalated: undefined,
                      }),
                  is_escalated: !potentialDuplicate.work_order_a.is_escalated,
                },
              };
            } else if (
              potentialDuplicate.work_order_b.id === action.payload.workorderId
            ) {
              return {
                ...potentialDuplicate,
                work_order_b: {
                  ...potentialDuplicate.work_order_b,
                  ...(!potentialDuplicate.work_order_b.is_escalated
                    ? {
                        working_on_it_user: action.payload.user.email,
                        working_on_it: true,
                        escalated_user: action.payload.user.email,
                      }
                    : {
                        is_escalated: undefined,
                      }),
                  is_escalated: !potentialDuplicate.work_order_b.is_escalated,
                },
              };
            }
            return potentialDuplicate;
          },
        ),
      };
    case Types.ADD_NOTE:
      return {
        ...state,
        potentialDuplicates: state.potentialDuplicates.map(
          (potentialDuplicate) => {
            if (potentialDuplicate.id === action.payload.id) {
              return {
                ...potentialDuplicate,
                notes: [action.payload.note, ...potentialDuplicate.notes],
              };
            } else {
              return potentialDuplicate;
            }
          },
        ),
      };
    default:
      return state;
  }
};

// ACTIONS

export const failure = (payload: { message: string; data?: any }): IAction => {
  return {
    type: Types.FAILURE,
    payload,
  };
};

export const warning = (payload: { message: string; data?: any }): IAction => {
  return {
    type: Types.WARNING,
    payload,
  };
};

export const fulfill = (payload?: string): IAction => {
  return {
    type: Types.FULFILL,
    payload,
  };
};

export const request = (payload?: string): IAction => {
  return {
    type: Types.REQUEST,
    payload,
  };
};

export const getPotentialDuplicates = (
  payload: IActionGetPoteltialDuplicates['payload'],
): IAction => {
  return {
    type: Types.GET_POTENTIAL_DUPLICATES,
    payload,
  };
};

export const setData = (payload: IActionSetData['payload']): IAction => {
  return {
    type: Types.SET_DATA,
    payload,
  };
};

export const getTradesOptions = (): IAction => {
  return {
    type: Types.GET_TRADES_OPTIONS,
  };
};

export const setTradesOptions = (
  payload: IActionSetTradesOptions['payload'],
): IAction => {
  return {
    type: Types.SET_TRADES_OPTIONS,
    payload,
  };
};

export const setFilter = (payload: IActionSetFilter['payload']): IAction => {
  return {
    type: Types.SET_FILTER,
    payload,
  };
};

export const updatePotentialDuplicate = (
  payload: IActionUpdatePotentialDuplicate['payload'],
): IAction => {
  return {
    type: Types.UPDATE_POTENTIAL_DUPLICATE,
    payload,
  };
};

export const toggleWorkspace = (): IAction => {
  return {
    type: Types.TOGGLE_WORKSPACE,
  };
};

export const toggleWorkingOnIt = (
  payload: IActionToggleWorkingOnIt['payload'],
): IAction => {
  return {
    type: Types.TOGGLE_WORKING_ON_IT,
    payload,
  };
};

export const toggleEscalated = (
  payload: IActionToggleEscalated['payload'],
): IAction => {
  return {
    type: Types.TOGGLE_ESCALATED,
    payload,
  };
};

export const submitNote = (payload: IActionSubmitNote['payload']): IAction => {
  return {
    type: Types.SUBMIT_NOTE,
    payload,
  };
};

export const addNote = (payload: IActionAddNote['payload']): IAction => {
  return {
    type: Types.ADD_NOTE,
    payload,
  };
};

export const confirmDuplicate = (
  payload: IActionConfirmDuplicate['payload'],
): IAction => {
  return {
    type: Types.CONFIRM_DUPLICATE,
    payload,
  };
};

export const declineDuplicate = (
  payload: IActionDeclineDuplicate['payload'],
): IAction => {
  return {
    type: Types.DECLINE_DUPLICATE,
    payload,
  };
};

export const actions = {
  failure,
  fulfill,
  request,
};
