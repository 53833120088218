// Models
import { IAction, IBaseState } from 'lib/redux/models'
import { IAppState } from 'storage/app/models'
import { NewFeatureStep } from 'storage/tutorial/models'

export enum EAuthActionTypes {
  FAILURE = 'AUTH/FAILURE',
  FULFILL = 'AUTH/FULFILL',
  REQUEST = 'AUTH/REQUEST',
  SUCCESS = 'AUTH/SUCCESS',
  LOGIN = 'AUTH/LOGIN',
  LOGIN_FROM_TOKEN = 'AUTH/LOGIN_FROM_TOKEN',
  REFRESH_TOKEN = 'AUTH/REFRESH_TOKEN',
  RESET_PASSWORD = 'AUTH/RESET_PASSWORD',
  RESET_PASSWORD_CONFIRM = 'AUTH/RESET_PASSWORD_CONFIRM',
  CHANGE_PASSWORD = 'AUTH/CHANGE_PASSWORD',
  RELEASE_EXPIRED_PASSWORD_LOCK = 'AUTH/RELEASE_EXPIRED_PASSWORD_LOCK',
  UPDATE_REFRESH_TIMEOUT = 'AUTH/UPDATE_REFRESH_TIMEOUT',
  LOGOUT = 'AUTH/LOGOUT',
  UPDATE_DEFAULT_REGION = 'AUTH/UPDATE_DEFAULT_REGION',
  SET_DEFAULT_REGION = 'AUTH/SET_DEFAULT_REGION',
  GET_USER_VIEWS = 'AUTH/GET_USER_VIEWS',
  ADD_USER_VIEW = 'AUTH/ADD_USER_VIEW',
  SAVE_USER_VIEW = 'AUTH/SAVE_USER_VIEW',
  REMOVE_USER_VIEW = 'AUTH/REMOVE_USER_VIEW',
  DELETE_USER_VIEW = 'AUTH/DELETE_USER_VIEW',
  GET_TEAM_VIEWS = 'AUTH/GET_TEAM_VIEWS',
  ADD_TEAM_VIEW = 'AUTH/ADD_TEAM_VIEW',
  SAVE_TEAM_VIEW = 'AUTH/SAVE_TEAM_VIEW',
  REMOVE_TEAM_VIEW = 'AUTH/REMOVE_TEAM_VIEW',
  DELETE_TEAM_VIEW = 'AUTH/DELETE_TEAM_VIEW',
  SET_TEAM_VIEW_AS_DEFAULT = 'AUTH/SET_TEAM_VIEW_AS_DEFAULT',
  SAVE_TEAM_VIEW_AS_DEFAULT = 'AUTH/SAVE_TEAM_VIEW_AS_DEFAULT',
  SORT_TEAM_VIEWS = 'AUTH/SORT_TEAM_VIEWS',
  GET_USER_STATS = 'AUTH/GET_USER_STATS',
  SET_USER_STATS = 'AUTH/SET_USER_STATS',
  GAIN_EXPERIENCE = 'AUTH/GAIN_EXPERIENCE',
  CLEAR_FLOATING_XP = 'AUTH/CLEAR_FLOATING_XP',
  LEVEL_UP = 'AUTH/LEVEL_UP',
  GET_LEADERBOARD = 'AUTH/GET_LEADERBOARD',
  SET_LEADERBOARD = 'AUTH/SET_LEADERBOARD',
  GET_NEW_FEATURES = 'AUTH/GET_NEW_FEATURES',
  SET_NEW_FEATURES = 'AUTH/SET_NEW_FEATURES',
}

// Data

export interface ILogin {
  email: string
  password: string
}

export interface IUser {
  first_name: string
  last_name: string
  token_type: string
  access_token: string
  refresh_token: string
  email: string
  exp: number
  refresh_exp: number
  iat: number
  jti: string
  stats?: {
    level: number
    experience: number
    next_level: number
    xp_count_daily: number
    xp_rank_current: number
    xp_count_monthly: number
    xp_rank_monthly: number
  }
  bot_admin: boolean
  note_sender: boolean
  default_region?: string[]
  default_market?: number[]
  is_password_expired?: boolean
  new_features?: NewFeatureStep[]
  team_admin?: boolean
  beta_user?: boolean
  potential_duplicate_workspace_access?: boolean
}

export interface RegionalMetric {
  region: string
  total: number
  wo_older_than_15_days: number
  wo_older_than_90_days: number
  wo_younger_than_15_days: number
  wo_younger_than_90_days: number
}

export interface UserRank {
  user_email: string
  user_first_name: string
  user_last_name: string
  team: string | null
  value: number
  rank: number
}

export interface RegionRank {
  name: string
  value: number
  rank: number
}

export interface UserView {
  id: string
  state: IAppState['filters']
  description: string
}

export interface TeamView {
  id: string
  state: Omit<IAppState['filters'], "region"|"market"|"club">
  description: string
  is_default: boolean
}

export interface ITokenResponse {
  access: string
  refresh: string
}

export interface IRefreshTokenRresponse {
  access: string
  refresh: string
  expires_in: number
}

// Actions

export interface IActionLogin extends IAction {
  type: EAuthActionTypes.LOGIN
  payload: ILogin
}

export interface IActionLogout extends IAction {
  type: EAuthActionTypes.LOGOUT
  payload: { isTimeout: boolean } | undefined
}

export interface IActionResetPassword extends IAction {
  type: EAuthActionTypes.RESET_PASSWORD
  payload: string
}

export interface IActionResetPasswordConfirm extends IAction {
  type: EAuthActionTypes.RESET_PASSWORD_CONFIRM
  payload: {
    password: string
    token: string
  }
}

export interface IActionChangePassword extends IAction {
  type: EAuthActionTypes.CHANGE_PASSWORD
  payload: {
    oldPassword: string
    newPassword: string
  }
}

export interface IActionUpdateDefaultRegion extends IAction {
  type: EAuthActionTypes.UPDATE_DEFAULT_REGION
  payload?: {default_region: string[] | null, default_market: number[] | null}
}

export interface IActionSetDefaultRegion extends IAction {
  type: EAuthActionTypes.SET_DEFAULT_REGION
  payload?: {default_region?: string[], default_market?: number[]}
}

export interface IActionGetUserViews extends IAction {
  type: EAuthActionTypes.GET_USER_VIEWS
}

export interface IActionAddUserView extends IAction {
  type: EAuthActionTypes.ADD_USER_VIEW
  payload?: {
    state: IAppState['filters']
    description: string
  }
}

export interface IActionSaveUserView extends IAction {
  type: EAuthActionTypes.SAVE_USER_VIEW
  payload?: {
    state: IAppState['filters']
    description: string
  }
}

export interface IActionRemoveUserView extends IAction {
  type: EAuthActionTypes.REMOVE_USER_VIEW
  payload?: UserView['id']
}

export interface IActionDeleteUserView extends IAction {
  type: EAuthActionTypes.DELETE_USER_VIEW
  payload?: UserView['id']
}

export interface IActionGetTeamViews extends IAction {
  type: EAuthActionTypes.GET_TEAM_VIEWS
}

export interface IActionAddTeamView extends IAction {
  type: EAuthActionTypes.ADD_TEAM_VIEW
  payload?: {
    state: IAppState['filters']
    description: string
  }
}

export interface IActionSaveTeamView extends IAction {
  type: EAuthActionTypes.SAVE_TEAM_VIEW
  payload: {
    state: IAppState['filters']
    description: string
    is_default: boolean
  }
}

export interface IActionRemoveTeamView extends IAction {
  type: EAuthActionTypes.REMOVE_TEAM_VIEW
  payload?: TeamView['id']
}

export interface IActionDeleteTeamView extends IAction {
  type: EAuthActionTypes.DELETE_TEAM_VIEW
  payload?: TeamView['id']
}

export interface IActionSaveTeamViewAsDefault extends IAction {
  type: EAuthActionTypes.SAVE_TEAM_VIEW_AS_DEFAULT
  payload?: {id: TeamView['id'], is_default: boolean}
}

export interface IActionGetTeamStats extends IAction {
  type: EAuthActionTypes.GET_USER_STATS
}

export interface IActionSetUserStats extends IAction {
  type: EAuthActionTypes.SET_USER_STATS
  payload?: IUser['stats']
}

// State

export interface IAuthState extends IBaseState {
  user?: IUser
  views: UserView[]
  teamViews: TeamView[]
  loggedIn: boolean
  loggedOut: boolean
  newXP?: number
  leaderboards?: {
    xp: UserRank[]
    notes: UserRank[]
    escalated: UserRank[]
    workingOnIt: UserRank[]
    region15Days: RegionRank[]
    region90Days: RegionRank[]
  }
}
