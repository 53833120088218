// Models

import { StringItem } from 'components/FilterBar/FilterMenu';
import { IBaseState } from 'lib/redux/models';
import { AppFilters, Workorder } from 'storage/app/models';
import { IUser } from 'storage/auth/models';

export enum EPotentialDuplicatesActionTypes {
  FAILURE = 'POTENTIAL_DUPLICATES/FAILURE',
  WARNING = 'POTENTIAL_DUPLICATES/WARNING',
  FULFILL = 'POTENTIAL_DUPLICATES/FULFILL',
  REQUEST = 'POTENTIAL_DUPLICATES/REQUEST',
  GET_POTENTIAL_DUPLICATES = 'POTENTIAL_DUPLICATES/GET_POTENTIAL_DUPLICATES',
  SET_DATA = 'POTENTIAL_DUPLICATES/SET_DATA',
  GET_TRADES_OPTIONS = 'POTENTIAL_DUPLICATES/GET_TRADES_OPTIONS',
  SET_TRADES_OPTIONS = 'POTENTIAL_DUPLICATES/SET_TRADES_OPTIONS',
  SET_FILTER = 'POTENTIAL_DUPLICATES/SET_FILTER',
  UPDATE_POTENTIAL_DUPLICATE = 'POTENTIAL_DUPLICATES/UPDATE_POTENTIAL_DUPLICATE',
  TOGGLE_WORKSPACE = 'POTENTIAL_DUPLICATES/TOGGLE_WORKSPACE',
  TOGGLE_WORKING_ON_IT = 'POTENTIAL_DUPLICATES/TOGGLE_WORKING_ON_IT',
  TOGGLE_ESCALATED = 'POTENTIAL_DUPLICATES/TOGGLE_ESCALATED',
  SUBMIT_NOTE = 'POTENTIAL_DUPLICATES/SUBMIT_NOTE',
  ADD_NOTE = 'POTENTIAL_DUPLICATES/ADD_NOTE',
  CONFIRM_DUPLICATE = 'POTENTIAL_DUPLICATES/CONFIRM_DUPLICATE',
  DECLINE_DUPLICATE = 'POTENTIAL_DUPLICATES/DECLINE_DUPLICATE',
}

export interface PotentialDuplicateNote {
  id: number;
  note: string;
  created_at: string;
  created_by: string;
  potential_duplicate?: number;
}

export interface PotentialDuplicate {
  id: number;
  trade: string;
  provider_name: string;
  store_id: string;
  market: string;
  region: string;
  equipment_id: number;
  equipment_serial_no: number;
  equipment_model_no: string;
  call_date_difference: string;
  has_declined: boolean;
  mentions_duplicate: boolean;
  inconsistent_serial_number: boolean;
  is_duplicate: boolean | null;
  work_order_a: Partial<Workorder>;
  work_order_b: Partial<Workorder>;
  notes: {
    id: number;
    note: string;
    created_at: string;
    created_by: string;
  }[];
}

export interface IActionGetPoteltialDuplicates {
  type: EPotentialDuplicatesActionTypes.GET_POTENTIAL_DUPLICATES;
  payload: {
    filters: Partial<AppFilters>;
    page: number;
  };
}

export interface IActionSetData {
  type: EPotentialDuplicatesActionTypes.SET_DATA;
  payload: PotentialDuplicate[];
}

export interface IActionGetTradesOptions {
  type: EPotentialDuplicatesActionTypes.GET_TRADES_OPTIONS;
}

export interface IActionSetTradesOptions {
  type: EPotentialDuplicatesActionTypes.SET_DATA;
  payload: string[];
}

export interface IActionSetFilter {
  type: EPotentialDuplicatesActionTypes.SET_FILTER;
  payload: {
    filter: keyof IPotentialDuplicatesState['filters'];
    value: boolean | null;
  };
}

export interface IActionUpdatePotentialDuplicate {
  type: EPotentialDuplicatesActionTypes.UPDATE_POTENTIAL_DUPLICATE;
  payload: { id: number; data: Partial<PotentialDuplicate> };
}

export interface IActionToggleWorkingOnIt {
  type: EPotentialDuplicatesActionTypes.TOGGLE_WORKING_ON_IT;
  payload: { workorderId: number; user: IUser };
}

export interface IActionToggleEscalated {
  type: EPotentialDuplicatesActionTypes.TOGGLE_ESCALATED;
  payload: { workorderId: number; user: IUser };
}

export interface IActionSubmitNote {
  type: EPotentialDuplicatesActionTypes.SUBMIT_NOTE;
  payload: { id: number; note: string };
}

export interface IActionAddNote {
  type: EPotentialDuplicatesActionTypes.ADD_NOTE;
  payload: { id: number; note: PotentialDuplicateNote };
}

export interface IActionConfirmDuplicate {
  type: EPotentialDuplicatesActionTypes.CONFIRM_DUPLICATE;
  payload: { id: number };
}

export interface IActionDeclineDuplicate {
  type: EPotentialDuplicatesActionTypes.DECLINE_DUPLICATE;
  payload: { id: number };
}

export interface IPotentialDuplicatesState extends IBaseState {
  isWorkspaceOpen: boolean;
  potentialDuplicates: PotentialDuplicate[];
  filters: {
    declined: boolean;
    mentionsDuplicate: boolean;
    isDuplicate: boolean | null;
  };
  filtersOptions: {
    trades: StringItem[];
  };
  page: number;
}
