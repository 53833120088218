/* eslint-disable @typescript-eslint/no-explicit-any */
import api from 'lib/axios/api';
import {
  IActionConfirmDuplicate,
  IActionGetPoteltialDuplicates,
  IActionSubmitNote,
  PotentialDuplicate,
  PotentialDuplicateNote,
} from 'storage/potentialDuplicates/models';
import { filterToParams } from 'utils/utils';

/**
 * Get potential duplicates workorders for workspace
 */
export interface GetPotentialDuplicatesResponse {
  message: string;
  data: {
    count: number;
    next: number | null;
    previous: number | null;
    results: PotentialDuplicate[];
  };
}
export const getPotentialDuplicatesService = async (
  payload: IActionGetPoteltialDuplicates['payload'],
): Promise<GetPotentialDuplicatesResponse['data']> => {
  const response: GetPotentialDuplicatesResponse = await api.get(
    `/api/v1/workorders/potential-duplicates/`,
    { params: { ...filterToParams(payload.filters), page: payload.page } },
  );
  const potentialDuplicates = response.data.results.map(
    (potentialDuplicate) => {
      const work_order_a_id = potentialDuplicate.work_order_a.number
        ? parseInt(potentialDuplicate.work_order_a.number, 10)
        : undefined;
      const work_order_b_id = potentialDuplicate.work_order_b.number
        ? parseInt(potentialDuplicate.work_order_b.number, 10)
        : undefined;
      return {
        ...potentialDuplicate,
        work_order_a: {
          ...potentialDuplicate.work_order_a,
          id: work_order_a_id,
        },
        work_order_b: {
          ...potentialDuplicate.work_order_b,
          id: work_order_b_id,
        },
      };
    },
  );
  return { ...response.data, results: potentialDuplicates };
};

/**
 * Get trade options for potential duplicates
 */
export interface GetTradeOptionsResponse {
  message: string;
  data: string[];
}

export const getTradeOptionsService = async (): Promise<
  GetTradeOptionsResponse['data']
> => {
  const response: GetTradeOptionsResponse = await api.get(
    `/api/v1/workorders/potential-duplicates/trades/`,
  );
  return response.data;
};

/**
 * Add a note to a potential workorder
 */
export interface SubmitNoteResponse {
  message: string;
  data: PotentialDuplicateNote;
}

export const submitNoteService = async (
  payload: IActionSubmitNote['payload'],
): Promise<PotentialDuplicateNote> => {
  const response: SubmitNoteResponse = await api.post(
    `/api/v1/workorders/potential-duplicates/${payload.id}/notes/add/`,
    { note: payload.note },
  );
  return response.data;
};

/**
 * Confirm a potential duplicate workorder
 */
export interface ConfirmDuplicateResponse {
  message: string;
  data: PotentialDuplicate;
}

export const confirmDuplicateService = async (
  payload: IActionConfirmDuplicate['payload'],
): Promise<ConfirmDuplicateResponse> => {
  const response: { data: ConfirmDuplicateResponse } = await api.post(
    `/api/v1/workorders/potential-duplicates/${payload.id}/set-status/`,
    { is_duplicate: 'true' },
  );
  return response.data;
};

/**
 * Decline a potential duplicate workorder
 */
export interface DeclineDuplicateResponse {
  message: string;
  data: PotentialDuplicate;
}

export const declineDuplicateService = async (
  payload: IActionConfirmDuplicate['payload'],
): Promise<DeclineDuplicateResponse> => {
  const response: { data: ConfirmDuplicateResponse } = await api.post(
    `/api/v1/workorders/potential-duplicates/${payload.id}/set-status/`,
    { is_duplicate: 'false' },
  );
  return response.data;
};
