import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState } from 'storage/auth/models';
import { IAppState, Proposal, Workorder } from 'storage/app/models';
import ServiceChannelIcon from '../../assets/service-channel-icon.png';
import {
  getWorkorder,
  setActiveWorkorder,
  setClub,
  setWorkorder,
  updateBotShutOff,
  toggleEscalated,
  toggleWorkingOnIt,
} from 'storage/app/duck';
import { getRecipients } from 'storage/notes/duck';
import {
  formatDollars,
  getBsiCircleColor,
  getBsiCircleTooltip,
} from 'utils/utils';
import {
  Stack,
  Typography,
  Box,
  IconButton,
  Button,
  Paper,
  Tooltip,
  Link,
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import ActionSwitch from 'components/ActionSwitch';
import ConstructionIcon from '@mui/icons-material/Construction';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import theme from 'theme';
import { gainExperience } from 'storage/auth/duck';
import { trackEvent } from 'storage/tracking/duck';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import TimeAgo from './TimeAgo';
import WorkorderNotes from './WorkorderNotes';
import LinkedWorkorders from './LinkedWorkorders';
import WorkorderAttachments from './WorkorderAttachments';
import './WorkorderDetail.css';
import Spinner from 'components/Spinner';
import PotentialDuplicates from './PotentialDuplicates';
import { isTablet } from 'utils/utils';

const WorkorderDetail: FC = () => {
  const dispatch = useDispatch();
  const map = useSelector<IStore, IAppState['map']>((state) => state.app.map);
  const user = useSelector<IStore, IAuthState['user']>(
    (state) => state.auth.user,
  );
  const filters = useSelector<IStore, IAppState['filters']>(
    (state) => state.app.filters,
  );
  const metadata = useSelector<IStore, IAppState['metadata']>(
    (state) => state.app.metadata,
  );
  const workorderData = useSelector<IStore, Workorder | undefined>(
    (state) => state.app.data.activeWorkorder,
  );
  const workorderId = useSelector<IStore, IAppState['filters']['workorder']>(
    (state) => state.app.filters.workorder,
  );
  const expConstants = useSelector<
    IStore,
    IAppState['metadata']['xp_multipliers']
  >((state) => state.app.metadata.xp_multipliers);
  const [workingOnItTooltip, setWorkingOnItTooltip] =
    useState('Add to follow up');
  const [escalatedTooltip, setEscalatedTooltip] = useState(
    'Escalate this work order',
  );
  const [botShutOffTooltip, setBotShutOffTooltip] = useState('Start bot');
  const [isVisible, setIsVisible] = useState(workorderData ? true : false);
  const [shouldGainXP, setShouldGainXP] = useState(false);
  const havePermission = workorderData?.working_on_it
    ? workorderData?.working_on_it_user !== user?.email
      ? false
      : true
    : true;
  const isScheduleDateInThePast = workorderData?.scheduled_date
    ? new Date(workorderData.scheduled_date) < new Date()
    : false;
  const loading = Object.keys(workorderData || {}).length <= 6;
  const equipment = {
    tag: workorderData?.equipment_tag,
    type: workorderData?.equipment_type,
    id: workorderData?.equipment_id,
  };
  const ipad = isTablet();

  useEffect(() => {
    if (workorderData?.id) {
      dispatch(
        trackEvent({
          namespace: 'View WO details',
          predicate: 'WO details',
          value: workorderData?.id.toString(),
        }),
      );
    }
  }, [workorderData?.id]);

  useEffect(() => {
    if (workorderId) {
      if (filters.callDate.start && filters.callDate.end) {
        dispatch(getWorkorder({ id: workorderId, filters: filters }));
      }
      dispatch(getRecipients(workorderId));
    }
  }, [workorderId]);

  useEffect(() => {
    if (workorderData?.id) {
      dispatch(setActiveWorkorder(undefined));
      dispatch(setWorkorder(undefined));
    }
  }, [
    filters.region,
    filters.market,
    filters.club,
    filters.trades,
    filters.tradeGroups,
    filters.providers,
    filters.categories,
    filters.status,
    filters.bsi,
    filters.nte,
    filters.workingOnIt,
    filters.escalated,
    filters.noActivitySince,
    filters.equipmentTypes,
    filters.notOperationalOnly,
    filters.missedETA,
    filters.potentialDuplicateOnly,
    filters.workingOnItUser,
    filters.escalatedUser,
    filters.dateRange,
  ]);

  useEffect(() => {
    setWorkingOnItTooltip(
      workorderData?.working_on_it
        ? user?.email === workorderData.working_on_it_user
          ? 'Follow up'
          : `${workorderData.working_on_it_user} is following up`
        : 'Add to follow up',
    );
    if (expConstants && shouldGainXP && workorderData) {
      dispatch(
        gainExperience(
          workorderData.working_on_it
            ? expConstants.working_on_it
            : -expConstants.working_on_it,
        ),
      );
      setShouldGainXP(false);
    }
  }, [workorderData?.working_on_it]);

  useEffect(() => {
    setEscalatedTooltip(
      workorderData?.is_escalated
        ? user?.email === workorderData.escalated_user
          ? 'Escalated'
          : `${workorderData.escalated_user} escalated this work order`
        : 'Escalate this work order',
    );
    if (expConstants && shouldGainXP) {
      dispatch(
        gainExperience(
          workorderData?.is_escalated
            ? expConstants.escalated
            : -expConstants.escalated,
        ),
      );
      setShouldGainXP(false);
    }
  }, [workorderData?.is_escalated]);

  useEffect(() => {
    // Temporarily disable Parts Delivered/Shipped and Parts on Order bots notification
    const filteredBots = metadata?.bot_status?.filter(
      (bot) =>
        bot.display_name !== 'Parts Delivered/Shipped' &&
        bot.display_name !== 'Parts on Order',
    );
    const areAllBotsActive = filteredBots?.every((bot) => bot.is_active);
    const inactiveBots = filteredBots?.filter((bot) => !bot.is_active);
    const activeBotLabel = areAllBotsActive
      ? 'All bots are running'
      : `${inactiveBots
          ?.map((bot) => bot.display_name + ' Bot')
          .join(', ')} inactive`;
    setBotShutOffTooltip(
      user?.bot_admin
        ? workorderData?.is_bot_shut_off
          ? 'Bot paused'
          : activeBotLabel
        : workorderData?.is_bot_shut_off
        ? "Bot paused (you don't have permission to manage)"
        : `${activeBotLabel} (you don't have permission to manage)`,
    );
    // user?.email === workorderData.bot_shut_off_user ?
    //   'Start bot' :
    //   `Bot paused by ${workorderData.bot_shut_off_user}` :
    // 'Pause bot')
  }, [workorderData?.is_bot_shut_off]);

  useEffect(() => {
    setIsVisible(workorderData ? true : false);
  }, [workorderData]);

  const handleBack = () => {
    setIsVisible(false);
    dispatch(
      trackEvent({
        namespace: 'Close WO details',
        predicate: 'WO details',
        value: workorderData?.id.toString(),
      }),
    );
    setTimeout(() => {
      dispatch(setActiveWorkorder(undefined));
      dispatch(setWorkorder(undefined));
    }, 500);
  };

  const handleUserActivity = (namespace: string, payloadValue: boolean) => {
    if (user && workorderData && expConstants) {
      dispatch(
        trackEvent({
          namespace: namespace,
          predicate: 'WO details',
          value: workorderData?.id.toString(),
          payload: { value: payloadValue },
        }),
      );
    }
  };

  const handleCheckWorkingOnIt = () => {
    if (user && workorderData && expConstants) {
      setShouldGainXP(true);
      dispatch(toggleWorkingOnIt({ workorderId: workorderData.id }));
    }
  };

  const handleCheckEscalated = () => {
    if (user && workorderData) {
      setShouldGainXP(true);
      dispatch(toggleEscalated({ workorderId: workorderData.id }));
    }
  };

  const handleCheckBotShutOff = () => {
    if (user && workorderData) {
      dispatch(
        updateBotShutOff({
          workorderId: workorderData.id,
          value: !workorderData.is_bot_shut_off,
          user: !workorderData.is_bot_shut_off ? user.email : undefined,
        }),
      );
      dispatch(
        trackEvent({
          namespace: 'Shut off bot',
          predicate: 'WO details',
          value: workorderData.id.toString(),
          payload: { value: !workorderData.is_bot_shut_off },
        }),
      );
    }
  };

  const handleZoomToClub = () => {
    if (map && workorderData) {
      const location: [number, number] =
        workorderData?.club_longitude && workorderData?.club_latitude
          ? [workorderData?.club_longitude, workorderData?.club_latitude]
          : [0, 0];
      if (location) {
        dispatch(
          setClub(workorderData?.club_id ? [workorderData?.club_id] : []),
        );
        map.flyTo({ center: location, zoom: 14, duration: 2000 });
      }
      dispatch(
        trackEvent({
          namespace: 'Zoom to club',
          predicate: 'WO details',
          value: workorderData.id.toString(),
          payload: {
            location: location,
            club_id: workorderData?.club_id
              ? parseInt(workorderData.club_id)
              : undefined,
          },
        }),
      );
    }
  };

  return (
    <Paper
      id="workorder-detail"
      sx={{
        backgroundColor: 'background.default',
        pl: ipad ? 1 : 2,
        pb: 2,
        position: 'absolute',
        top: 0,
        left: 0,
        transform: isVisible ? 'translate(0, 0)' : 'translate(-100%, 0)',
        transition: '0.25s',
        height: '100%',
        width: '100%',
        borderRight: ipad ? 'unset' : '4px solid',
        borderRightColor: 'background.default',
      }}>
      {workorderData && (
        <>
          <Stack
            sx={{
              position: 'absolute',
              p: '1rem',
              top: '0',
              left: '0',
              right: '0',
              height: '8.5rem',
              backgroundColor: theme.palette.background.light,
            }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip title="Return to list" placement="top" arrow>
                <IconButton edge="start" onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" component="h2" fontWeight="bold">
                Work order {workorderData.id}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ marginLeft: 'auto !important' }}>
                <Tooltip title="Zoom to Club" placement="top" arrow>
                  <IconButton size="small" onClick={handleZoomToClub}>
                    <GpsFixedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Open in Service Channel" placement="top" arrow>
                  <IconButton
                    size="small"
                    href={`${metadata.sc_portal_url}/sc/wo/Workorders/index?id=${workorderData.id}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      src={ServiceChannelIcon}
                      width="26"
                      height="26"
                      alt="Service Channel"
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
            <Stack spacing={3} mt={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <ActionSwitch
                  tooltip={workingOnItTooltip}
                  label={'Follow up'}
                  labelWidth={ipad ? '100%' : '10rem'}
                  checked={workorderData.working_on_it ? true : false}
                  backgroundColor={
                    workorderData.working_on_it
                      ? workorderData.working_on_it_user === user?.email
                        ? theme.palette.actions.userWorkingOnIt
                        : theme.palette.actions.workingOnIt
                      : '#8D8D8D'
                  }
                  icon={
                    <ConstructionIcon
                      sx={{
                        color: workorderData.working_on_it
                          ? theme.palette.actions.workingOnIt
                          : '#8D8D8D',
                      }}
                    />
                  }
                  havePermission={havePermission}
                  onClick={handleCheckWorkingOnIt}
                  onChange={(checked) =>
                    handleUserActivity('Working on it', checked)
                  }
                />
                <ActionSwitch
                  tooltip={escalatedTooltip}
                  label={'Escalated'}
                  labelWidth={ipad ? '100%' : '9rem'}
                  checked={workorderData.is_escalated ? true : false}
                  backgroundColor={
                    workorderData.is_escalated
                      ? workorderData.escalated_user === user?.email
                        ? theme.palette.actions.userEscalated
                        : theme.palette.actions.escalated
                      : '#8D8D8D'
                  }
                  icon={
                    <LocalFireDepartmentIcon
                      sx={{
                        color: workorderData.is_escalated
                          ? theme.palette.actions.escalated
                          : '#8D8D8D',
                      }}
                    />
                  }
                  havePermission={havePermission}
                  onClick={handleCheckEscalated}
                  onChange={(checked) =>
                    handleUserActivity('Escalate', checked)
                  }
                />
                <ActionSwitch
                  tooltip={botShutOffTooltip}
                  labelWidth={ipad ? '100%' : '9rem'}
                  label={'Bot active'}
                  checked={!workorderData?.is_bot_shut_off ? true : false}
                  backgroundColor={
                    !workorderData?.is_bot_shut_off
                      ? theme.palette.actions.userWorkingOnIt
                      : '#8D8D8D'
                  }
                  icon={
                    <SmartToyIcon
                      sx={{
                        color: !workorderData?.is_bot_shut_off
                          ? theme.palette.actions.workingOnIt
                          : '#8D8D8D',
                      }}
                    />
                  }
                  havePermission={user?.bot_admin ? true : false}
                  onClick={user?.bot_admin ? handleCheckBotShutOff : undefined}
                />
              </Stack>
            </Stack>
          </Stack>
          {loading ? (
            <Spinner isVisible />
          ) : (
            <Stack
              sx={{
                overflow: 'auto',
                position: 'absolute',
                top: '8.5rem',
                bottom: 0,
                pr: ipad ? '0.75rem' : '0.5rem',
                pb: '1rem',
              }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                mt={2}
                mb={1}>
                <Tooltip
                  title={
                    workorderData?.priority_bin &&
                    getBsiCircleTooltip(workorderData?.priority_bin)
                  }
                  placement="top"
                  arrow>
                  <Box
                    className="circle"
                    sx={{
                      backgroundColor:
                        workorderData?.priority_bin &&
                        getBsiCircleColor(workorderData?.priority_bin),
                      boxShadow: (theme) => ({ boxShadow: theme.shadows[5] }),
                    }}
                  />
                </Tooltip>
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    mt: '0.125rem !important',
                    ml: '0.75rem !important',
                    fontWeight: 800,
                  }}>
                  {workorderData.trade}
                  {workorderData.problem_code
                    ? ` | ${workorderData.problem_code}`
                    : null}
                </Typography>
              </Stack>
              <Stack spacing={2} direction="row">
                {workorderData.category && (
                  <Typography style={{ fontSize: '1.25rem' }}>
                    {workorderData.category}
                  </Typography>
                )}
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                sx={{ pb: '0.5rem', borderBottom: '1px solid', mb: '0.5rem' }}>
                <Typography>{workorderData.primary_status}</Typography>
                {workorderData.extended_status && (
                  <>
                    <Typography>-</Typography>
                    <Typography>{workorderData.extended_status}</Typography>
                  </>
                )}
              </Stack>
              <Stack
                direction="row"
                flexWrap={'wrap'}
                justifyContent="space-between"
                sx={{ paddingBottom: '1rem', borderBottom: '1px solid' }}>
                <Stack width="45%">
                  <Typography sx={{ fontSize: '1.25rem' }}>
                    {workorderData.priority}
                  </Typography>
                  <Stack direction="row" mt="1rem">
                    <Typography sx={{ fontSize: '1.25rem' }}>
                      <strong>NTE:</strong>{' '}
                      {workorderData?.nte && formatDollars(workorderData.nte)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack width="55%" pl="1rem" pt="0.15rem">
                  <Tooltip
                    title={
                      workorderData?.created_date &&
                      new Date(workorderData.created_date).toLocaleString()
                    }
                    placement="left"
                    arrow>
                    <Typography sx={{ color: '#fff' }}>
                      <strong>Created:</strong>{' '}
                      {workorderData?.created_date && (
                        <TimeAgo date={new Date(workorderData.created_date)} />
                      )}
                    </Typography>
                  </Tooltip>
                  {workorderData.call_date &&
                    workorderData.call_date !== 'nan' && (
                      <Tooltip
                        title={new Date(
                          workorderData.call_date,
                        ).toLocaleString()}
                        placement="left"
                        arrow>
                        <Typography sx={{ color: '#fff' }}>
                          <strong>Call Date:</strong>{' '}
                          <TimeAgo date={new Date(workorderData.call_date)} />
                        </Typography>
                      </Tooltip>
                    )}
                  {workorderData.scheduled_date &&
                    workorderData.scheduled_date !== 'nan' && (
                      <Tooltip
                        title={new Date(
                          workorderData.scheduled_date,
                        ).toLocaleString()}
                        placement="left"
                        arrow>
                        <Typography sx={{ color: '#fff' }}>
                          {isScheduleDateInThePast ? (
                            <strong>
                              Scheduled:{' '}
                              <TimeAgo
                                date={new Date(workorderData.scheduled_date)}
                              />
                            </strong>
                          ) : (
                            <>
                              <strong>Scheduled: </strong>{' '}
                              <TimeAgo
                                date={new Date(workorderData.scheduled_date)}
                              />
                            </>
                          )}
                        </Typography>
                      </Tooltip>
                    )}
                  {workorderData.completed_date &&
                    workorderData.completed_date !== 'nan' && (
                      <Tooltip
                        title={new Date(
                          workorderData.completed_date,
                        ).toLocaleString()}
                        placement="left"
                        arrow>
                        <Typography sx={{ color: '#fff' }}>
                          <strong>Work Date:</strong>{' '}
                          <TimeAgo
                            date={new Date(workorderData.completed_date)}
                          />
                        </Typography>
                      </Tooltip>
                    )}
                  <Tooltip
                    title={
                      workorderData?.updated_date &&
                      new Date(workorderData.updated_date).toLocaleString()
                    }
                    placement="left"
                    arrow>
                    <Typography sx={{ color: '#ffffff' }}>
                      <strong>Updated:</strong>{' '}
                      {workorderData?.updated_date && (
                        <TimeAgo date={new Date(workorderData.updated_date)} />
                      )}
                    </Typography>
                  </Tooltip>
                </Stack>
                {workorderData.proposals &&
                  workorderData.proposals.length > 0 &&
                  workorderData.proposals.map((proposal: Proposal) => (
                    <Button
                      key={proposal.proposal_id}
                      variant="contained"
                      color="secondary"
                      component="a"
                      target={'_blank'}
                      href={`${metadata.sc_portal_url}/sc/Proposal/ProposalsRfp/ViewProposals?ProposalIids=${proposal.proposal_id}`}
                      fullWidth
                      sx={{ mt: '1rem' }}>
                      <RequestPageIcon />
                      <Typography
                        component="span"
                        fontSize="0.9rem"
                        margin="auto"
                        lineHeight="0.9rem"
                        textAlign="center">
                        Proposal {proposal.proposal_number}
                        <br />
                        <span style={{ fontSize: '0.8rem' }}>
                          ({proposal.proposal_status},{' '}
                          <TimeAgo
                            date={new Date(proposal.proposal_date)}
                            hideHours
                          />
                          )
                        </span>
                      </Typography>
                    </Button>
                  ))}
              </Stack>
              <Stack
                spacing={4}
                direction="row"
                sx={{
                  mt: '1rem',
                  paddingBottom: '1rem',
                  borderBottom: '1px solid',
                }}>
                <Stack spacing={1} sx={{ width: '45%' }}>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    Provider
                  </Typography>
                  <Box>
                    <Stack direction="row" mb="0.5rem">
                      <PersonIcon
                        fontSize="small"
                        sx={{ marginRight: '0.25rem' }}
                      />
                      <Typography>{workorderData.provider_name}</Typography>
                    </Stack>
                    <Stack direction="row">
                      <PhoneIcon
                        fontSize="small"
                        sx={{ marginRight: '0.25rem' }}
                      />
                      <Typography>{workorderData.provider_phone}</Typography>
                    </Stack>
                  </Box>
                </Stack>
                <Stack spacing={1} sx={{ width: '55%' }}>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    Club {workorderData.club_id}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ marginTop: 0 }}>
                    {workorderData.region_name}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ marginTop: 0 }}>
                    Market {workorderData.market_id}
                  </Typography>
                  <Box>
                    <Stack direction="row" mb="0.5rem">
                      <PersonIcon
                        fontSize="small"
                        sx={{ marginRight: '0.25rem' }}
                      />
                      <Typography>
                        <span style={{ fontWeight: 800 }}>Manager:</span>{' '}
                        {workorderData.club_manager_name}
                      </Typography>
                    </Stack>
                    <Stack direction="row">
                      <PhoneIcon
                        fontSize="small"
                        sx={{ marginRight: '0.25rem' }}
                      />
                      <Stack>
                        {workorderData.club_manager_cell_phone && (
                          <Typography>
                            <span style={{ fontWeight: 800 }}>Manager:</span>{' '}
                            {workorderData.club_manager_cell_phone}
                          </Typography>
                        )}
                        {workorderData.club_phone_number && (
                          <Typography>
                            <span style={{ fontWeight: 800 }}>Club:</span>{' '}
                            {workorderData.club_phone_number}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
              <Stack spacing={1} sx={{ mt: '1rem' }}>
                <Typography variant="h6" component="h3" fontWeight="bold">
                  Description
                </Typography>
                <Typography>{workorderData.description}</Typography>
              </Stack>
              {equipment.tag && equipment.type && equipment.id && (
                <Stack spacing={1} mt={2}>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    Asset
                  </Typography>
                  <Box>
                    <Link
                      href={`${metadata.sc_portal_url}/em/assets?AssetId=${equipment.id}`}
                      target="_blank"
                      rel="noreferrer">
                      <Typography
                        color="primary"
                        sx={{ ':hover': { textDecoration: 'underline' } }}>
                        {equipment.tag} ({equipment.type})
                      </Typography>
                    </Link>
                  </Box>
                </Stack>
              )}
              <LinkedWorkorders workorderData={{ ...workorderData }} />
              <WorkorderAttachments workorderData={{ ...workorderData }} />
              <PotentialDuplicates workorderData={{ ...workorderData }} />
              <WorkorderNotes workorderData={workorderData} />
            </Stack>
          )}
        </>
      )}
    </Paper>
  );
};

export default WorkorderDetail;
