import React, { FC, useEffect, useState } from 'react';
import theme from 'theme';
import {
  Button,
  Icon,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { PotentialDuplicate as IPotentialDuplicate } from 'storage/potentialDuplicates/models';
import { formatDollars } from 'utils/utils';
import TimeAgo from 'components/WorkorderDetail/TimeAgo';
import ConstructionIcon from '@mui/icons-material/Construction';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ActionSwitch from 'components/ActionSwitch';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState } from 'storage/auth/models';
import { toggleEscalated, toggleWorkingOnIt } from 'storage/app/duck';
import { trackEvent } from 'storage/tracking/duck';
import {
  toggleWorkingOnIt as pdToggleWorkingOnIt,
  toggleEscalated as pdToggleEscalated,
  submitNote,
  confirmDuplicate,
  declineDuplicate,
} from 'storage/potentialDuplicates/duck';
import ServiceChannelIcon from '../../assets/service-channel-icon.png';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { IAppState } from 'storage/app/models';

type IWorkorder =
  | IPotentialDuplicate['work_order_a']
  | IPotentialDuplicate['work_order_b'];

const Workorder: FC<IWorkorder> = (props) => {
  const dispatch = useDispatch();
  const user = useSelector<IStore, IAuthState['user']>(
    (state) => state.auth.user,
  );
  const [workingOnItTooltip, setWorkingOnItTooltip] =
    useState('Add to follow up');
  const [escalatedTooltip, setEscalatedTooltip] = useState(
    'Escalate this work order',
  );

  const havePermission = props.working_on_it
    ? props.working_on_it_user !== user?.email
      ? false
      : true
    : true;

  useEffect(() => {
    setWorkingOnItTooltip(
      props.working_on_it
        ? user?.email === props.working_on_it_user
          ? 'Follow up'
          : `${props.working_on_it_user} is following up`
        : 'Add to follow up',
    );
  }, [props.working_on_it_user, props.working_on_it]);

  useEffect(() => {
    setEscalatedTooltip(
      props.is_escalated
        ? user?.email === props.escalated_user
          ? 'Escalated'
          : `${props.escalated_user} escalated this work order`
        : 'Escalate this work order',
    );
  }, [props.escalated_user, props.is_escalated]);

  const handleCheckWorkingOnIt = (workorder: IWorkorder) => {
    if (user && workorder.id) {
      dispatch(toggleWorkingOnIt({ workorderId: workorder.id }));
      dispatch(pdToggleWorkingOnIt({ workorderId: workorder.id, user: user }));
    }
  };

  const handleCheckEscalated = (workorder: IWorkorder) => {
    if (user && workorder.id) {
      dispatch(toggleEscalated({ workorderId: workorder.id }));
      dispatch(pdToggleEscalated({ workorderId: workorder.id, user: user }));
    }
  };

  const handleUserActivity = (namespace: string, payloadValue: boolean) => {
    if (user) {
      dispatch(
        trackEvent({
          namespace: namespace,
          predicate: 'Potential duplicate workspace',
          value: props.id?.toString(),
          payload: { value: payloadValue },
        }),
      );
    }
  };

  return (
    <Stack sx={{ backgroundColor: '#763d00' }} width="100%">
      <Stack direction="row">
        <Stack
          direction="row"
          columnGap={2}
          whiteSpace="nowrap"
          flexWrap="wrap"
          p={1}>
          <Typography>
            <strong>WO:</strong> {props.id}
          </Typography>
          <Typography>
            <strong>Status:</strong> {props.primary_status}
          </Typography>
          <Typography>
            <strong>NTE:</strong> {props.nte && formatDollars(props.nte)}
          </Typography>
          <Typography whiteSpace="normal">
            <strong>Caller:</strong> {props.created_by} (
            {props.call_date_dto && (
              <TimeAgo date={new Date(props.call_date_dto)} />
            )}
            )
          </Typography>
          <Typography>
            <strong>Scheduled:</strong>{' '}
            {props.scheduled_date_dto && (
              <TimeAgo date={new Date(props.scheduled_date_dto)} />
            )}
          </Typography>
        </Stack>
        <Stack width="8rem" justifyContent="center" alignItems="center">
          <ActionSwitch
            tooltip={workingOnItTooltip}
            tooltipPlacement="right"
            checked={props.working_on_it ? true : false}
            backgroundColor={
              props.working_on_it
                ? props.working_on_it_user === user?.email
                  ? theme.palette.actions.userWorkingOnIt
                  : theme.palette.actions.workingOnIt
                : '#8D8D8D'
            }
            icon={
              <ConstructionIcon
                sx={{
                  color: props.working_on_it
                    ? theme.palette.actions.workingOnIt
                    : '#8D8D8D',
                }}
              />
            }
            havePermission={havePermission}
            onClick={() => handleCheckWorkingOnIt(props)}
            onChange={(checked) => handleUserActivity('Working on it', checked)}
          />
          <ActionSwitch
            tooltip={escalatedTooltip}
            tooltipPlacement="right"
            checked={props.is_escalated ? true : false}
            backgroundColor={
              props.is_escalated
                ? props.escalated_user === user?.email
                  ? theme.palette.actions.userEscalated
                  : theme.palette.actions.escalated
                : '#8D8D8D'
            }
            icon={
              <LocalFireDepartmentIcon
                sx={{
                  color: props.is_escalated
                    ? theme.palette.actions.escalated
                    : '#8D8D8D',
                }}
              />
            }
            havePermission={havePermission}
            onClick={() => handleCheckEscalated(props)}
            onChange={(checked) => handleUserActivity('Escalate', checked)}
          />
        </Stack>
      </Stack>
      <Stack borderTop={1} pt={1} mt={0.5} mx={1} py={1}>
        <Typography fontWeight={600}>{props.description}</Typography>
      </Stack>
    </Stack>
  );
};

const PotentialDuplicate: FC<IPotentialDuplicate> = (props) => {
  const dispatch = useDispatch();
  const [newNote, setNewNote] = useState('');
  const metadata = useSelector<IStore, IAppState['metadata']>(
    (state) => state.app.metadata,
  );

  const handleSubmitNote = () => {
    dispatch(submitNote({ id: props.id, note: newNote }));
    dispatch(
      trackEvent({
        namespace: 'Add note to potential duplicate',
        predicate: 'Potential duplicates workspace',
        payload: {
          id: props.id.toString(),
        },
        value: newNote,
      }),
    );
  };

  const handleConfirmDuplicate = () => {
    dispatch(confirmDuplicate({ id: props.id }));
    dispatch(
      trackEvent({
        namespace: 'Confirm duplicate',
        predicate: 'Potential duplicates workspace',

        value: props.id.toString(),
      }),
    );
  };

  const handleDeclineDuplicate = () => {
    dispatch(declineDuplicate({ id: props.id }));
    dispatch(
      trackEvent({
        namespace: 'Decline duplicate',
        predicate: 'Potential duplicates workspace',
        value: props.id.toString(),
      }),
    );
  };

  return (
    <Stack direction="row" p={1} gap={2} width="100%" borderBottom={1}>
      <Stack minWidth="12rem">
        <Typography fontWeight={600}>{props.trade}</Typography>
        <Typography>{props.provider_name}</Typography>
        <Typography>Club {props.store_id}</Typography>
        <Typography>
          Mkt {props.market}, {props.region}
        </Typography>
        <Typography>Asset ID: {props.equipment_id}</Typography>
        <Typography>Serial #: {props.equipment_serial_no}</Typography>
        <Typography>Model #: {props.equipment_model_no}</Typography>
        <Typography>Difference: {props.call_date_difference}</Typography>
        {(props.mentions_duplicate || props.inconsistent_serial_number) && (
          <Stack mt={1} gap={1}>
            {props.inconsistent_serial_number && (
              <Stack direction="row" gap={1}>
                <Icon fontSize="large">
                  <WarningIcon fontSize="large" />
                </Icon>
                <Typography fontSize="small">
                  Inconsistent Serial Number!
                </Typography>
              </Stack>
            )}
            {props.mentions_duplicate && (
              <Stack direction="row" gap={1}>
                <Icon fontSize="large">
                  <InfoIcon fontSize="large" />
                </Icon>
                <Typography fontSize="small">
                  &quot;Duplicate&quot; mentioned in notes
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
      <Workorder {...props.work_order_a} />
      <Workorder {...props.work_order_b} />
      <Stack width="75%">
        <Stack
          flexGrow={1}
          width="100%"
          minHeight="10rem"
          maxHeight="14rem"
          overflow="auto">
          {props.notes.length <= 0 && (
            <Stack justifyContent="center" alignItems="center" flexGrow={1}>
              <Typography color="gray" fontSize="large">
                No notes
              </Typography>
            </Stack>
          )}
          {props.notes.map((note, index) => (
            <Stack
              key={note.id}
              sx={{
                backgroundColor: '#763d00',
              }}
              p={1}
              mb={props.notes.length - 1 !== index ? 0.5 : undefined}
              py={1}>
              <Typography>{note.note}</Typography>
              <Typography variant="caption">
                {note.created_by} (
                <TimeAgo date={new Date(note.created_at)} />)
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack direction="row" width="100%" mt={1}>
          <TextField
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            variant="filled"
            sx={{ width: '100%' }}
            size="small"
            hiddenLabel
          />
          <Button
            variant="contained"
            sx={{ whiteSpace: 'nowrap' }}
            onClick={handleSubmitNote}>
            Add Note
          </Button>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-around"
          mt={1}>
          <Link
            href={`${metadata.sc_portal_url}/sc/wo/Workorders/list?id=${props.work_order_a.id},${props.work_order_b.id}`}
            target="_blank"
            rel="noreferrer">
            <Button>
              <Stack gap={1} justifyContent="center" alignItems="center">
                <img
                  src={ServiceChannelIcon}
                  width="26"
                  height="26"
                  alt="Service Channel"
                />
                Review in Service Channel
              </Stack>
            </Button>
          </Link>
          <Button
            disabled={props.is_duplicate === true}
            onClick={handleConfirmDuplicate}
            sx={{
              backgroundColor:
                props.is_duplicate === true ? '#03723b' : undefined,
              color: 'white !important',
            }}>
            <Stack gap={1} justifyContent="center" alignItems="center">
              <CheckIcon />
              Confirm Duplicate
            </Stack>
          </Button>
          <Button
            disabled={props.is_duplicate === false}
            onClick={handleDeclineDuplicate}
            sx={{
              backgroundColor:
                props.is_duplicate === false ? '#9a3131' : undefined,
              color: 'white !important',
            }}>
            <Stack gap={1} justifyContent="center" alignItems="center">
              <DoDisturbIcon />
              NOT a Duplicate
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PotentialDuplicate;
