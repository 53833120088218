import React, { FC, useEffect, useRef } from 'react';
import './PotentialDuplicateWorkspace.css';
import theme from 'theme';
import {
  Card,
  Button,
  Typography,
  Stack,
  CircularProgress,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPotentialDuplicates,
  getTradesOptions,
  toggleWorkspace,
} from 'storage/potentialDuplicates/duck';
import Filters from './Filters';
import { IAppState } from 'storage/app/models';
import { selectFilters } from 'storage/app/duck';
import IStore from 'lib/redux/models';
import { IPotentialDuplicatesState } from 'storage/potentialDuplicates/models';
import PotentialDuplicate from './PotentialDuplicate';

const PotentialDuplicateWorkspace: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector<IStore, IPotentialDuplicatesState['loading']>(
    (state) => state.potentialDuplicates.loading,
  );
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const workspaceFilters = useSelector<
    IStore,
    IPotentialDuplicatesState['filters']
  >((state) => state.potentialDuplicates.filters);
  const potentialDuplicates = useSelector<
    IStore,
    IPotentialDuplicatesState['potentialDuplicates']
  >((state) => state.potentialDuplicates.potentialDuplicates);
  const intervalRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    dispatch(getTradesOptions());
    intervalRef.current = window.setInterval(handleFetchData, 15000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    handleFetchData();
    clearInterval(intervalRef.current);
    intervalRef.current = window.setInterval(handleFetchData, 15000);
  }, [filters, workspaceFilters]);

  const handleFetchData = () => {
    dispatch(
      getPotentialDuplicates({
        filters: {
          region: filters.region,
          market: filters.market,
          trades: filters.trades,
          ...workspaceFilters,
        },
        page: 1,
      }),
    );
  };

  const handleCloseWorkspace = () => {
    dispatch(toggleWorkspace());
  };

  return (
    <Card
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: theme.palette.background.paper,
      }}>
      <Button
        variant="text"
        onClick={handleCloseWorkspace}
        sx={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          color: theme.palette.text.primary,
          minWidth: '2rem',
          height: '2rem',
          zIndex: 1101,
        }}>
        <CloseIcon />
      </Button>
      <Stack
        boxShadow={2}
        gap={1}
        justifyContent="end"
        alignItems="center"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          height: '8rem',
        }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          ml={7}>
          <Typography variant="h5">Potential Duplicate Workspace</Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box height="2.5rem" width="2.5rem"></Box>
          )}
        </Stack>
        <Filters />
      </Stack>
      <Stack
        justifyContent="start"
        alignItems="center"
        overflow="auto"
        sx={{
          position: 'absolute',
          top: '8rem',
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: '#522a00',
        }}>
        {potentialDuplicates &&
          potentialDuplicates.length > 0 &&
          potentialDuplicates.map((potentialDuplicate) => (
            <PotentialDuplicate
              key={potentialDuplicate.id}
              {...potentialDuplicate}
            />
          ))}
        {potentialDuplicates.length === 0 && (
          <Typography variant="h6" mt={4}>
            No potential duplicates found
          </Typography>
        )}
      </Stack>
    </Card>
  );
};

export default PotentialDuplicateWorkspace;
