import React, { FC, useEffect, useState } from 'react';
import theme from 'theme';
import { Button, Stack, Chip, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from 'storage/potentialDuplicates/duck';
import FilterMenu from 'components/FilterBar/FilterMenu';
import { IAppState, IAppStateDerived } from 'storage/app/models';
import {
  selectFilters,
  selectMarketOptions,
  selectRegionOptions,
  setMarket,
  setRegion,
  setTrades,
} from 'storage/app/duck';
import { trackEvent } from 'storage/tracking/duck';
import IStore from 'lib/redux/models';
import { IPotentialDuplicatesState } from 'storage/potentialDuplicates/models';

const Filters: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const workspaceFilters = useSelector<
    IStore,
    IPotentialDuplicatesState['filters']
  >((state) => state.potentialDuplicates.filters);
  const regionOptions = useSelector<IStore, IAppStateDerived['regionOptions']>(
    selectRegionOptions,
  );

  const [currentRegionOptions, setCurrentRegionOptions] = useState(
    filters.region,
  );
  const [regionMenuAnchorEl, setRegionMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const marketOptions = useSelector<IStore, IAppStateDerived['marketOptions']>(
    selectMarketOptions,
  ) as number[];

  const [currentMarketOptions, setCurrentMarketOptions] = useState(
    filters.market,
  );
  const [marketMenuAnchorEl, setMarketMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const [currentTradeOptions, setCurrentTradeOptions] = useState(
    filters.trades,
  );
  const { trades: tradeOptions } = useSelector(
    (state: IStore) => state.potentialDuplicates.filtersOptions,
  );
  const [tradeMenuAnchorEl, setTradeMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  useEffect(() => {
    setCurrentRegionOptions(filters.region);
  }, [filters.region]);

  useEffect(() => {
    setCurrentMarketOptions(filters.market);
  }, [filters.market]);

  useEffect(() => {
    setCurrentTradeOptions(filters.trades);
  }, [filters.trades]);

  const handleRegionChange = (value?: IAppState['filters']['region']) => {
    setCurrentRegionOptions(value);
  };

  const handleClearRegionFilters = () => {
    setCurrentRegionOptions(undefined);
    dispatch(setRegion(undefined));
    dispatch(
      trackEvent({
        namespace: 'Clear regions',
        predicate: 'Potential duplicates workspace filter bar',
      }),
    );
  };

  const handleApplyRegionFilters = () => {
    dispatch(setRegion(currentRegionOptions));
    dispatch(
      trackEvent({
        namespace: 'Set regions',
        predicate: 'Potential duplicates workspace filter bar',
        payload: currentRegionOptions
          ? { filter: currentRegionOptions }
          : undefined,
      }),
    );
    setRegionMenuAnchorEl(null);
  };

  const handleCancelRegionFilters = () => {
    setCurrentRegionOptions(filters.region);
    setRegionMenuAnchorEl(null);
  };

  const handleMarketChange = (value?: IAppState['filters']['market']) => {
    setCurrentMarketOptions(value);
  };

  const handleClearMarketFilters = () => {
    setCurrentMarketOptions(undefined);
    dispatch(setMarket(undefined));
    dispatch(
      trackEvent({
        namespace: 'Clear markets',
        predicate: 'Potential duplicates workspace filter bar',
      }),
    );
  };

  const handleApplyMarketFilters = () => {
    dispatch(setMarket(currentMarketOptions));
    dispatch(
      trackEvent({
        namespace: 'Set markets',
        predicate: 'Potential duplicates workspace filter bar',
        payload: currentMarketOptions
          ? { filter: currentMarketOptions }
          : undefined,
      }),
    );
    setMarketMenuAnchorEl(null);
  };

  const handleCancelMarketFilters = () => {
    setCurrentMarketOptions(filters.market);
    setMarketMenuAnchorEl(null);
  };

  const handleTradeChange = (value?: IAppState['filters']['trades']) => {
    setCurrentTradeOptions(value);
  };

  const handleClearTradeFilters = () => {
    setCurrentTradeOptions(undefined);
    dispatch(setTrades(undefined));
    dispatch(
      trackEvent({
        namespace: 'Clear trades',
        predicate: 'Potential duplicates workspace filter bar',
      }),
    );
  };

  const handleApplyTradeFilters = () => {
    dispatch(setTrades(currentTradeOptions));
    dispatch(
      trackEvent({
        namespace: 'Set trades',
        predicate: 'Potential duplicates workspace filter bar',
        payload: currentTradeOptions
          ? { filter: currentTradeOptions }
          : undefined,
      }),
    );
    setTradeMenuAnchorEl(null);
  };

  const handleCancelTradeFilters = () => {
    setCurrentTradeOptions(filters.trades);
    setTradeMenuAnchorEl(null);
  };

  const handleDeclinedChange = (value: boolean) => {
    dispatch(setFilter({ filter: 'declined', value: value }));
    dispatch(
      trackEvent({
        namespace: 'Set declined status filter',
        predicate: 'Potential duplicates workspace filter bar',
        value: value?.toString(),
      }),
    );
  };

  const handleDuplicateChange = (value: boolean) => {
    dispatch(setFilter({ filter: 'mentionsDuplicate', value: value }));
    dispatch(
      trackEvent({
        namespace: 'Set duplicate mentions status filter',
        predicate: 'Potential duplicates workspace filter bar',
        value: value?.toString(),
      }),
    );
  };

  const handlePendingChange = (value: boolean) => {
    dispatch(setFilter({ filter: 'isDuplicate', value: value ? null : false }));
    dispatch(
      trackEvent({
        namespace: 'Set show only pending entries filter',
        predicate: 'Potential duplicates workspace filter bar',
        value: value?.toString(),
      }),
    );
  };

  const getFilterLabel = (
    value: string[] | number[] | undefined,
    type: string,
  ) => {
    if (value) {
      if (value.length === 1) {
        if (type === 'Region') {
          return `${value[0]}`;
        } else {
          return `${type.slice(0, type.length - 1)} ${value[0]}`;
        }
      } else {
        return (
          <>
            {type}
            <Chip
              label={value.length}
              size="small"
              variant="outlined"
              sx={{
                ml: 1,
                backgroundColor: theme.palette.background.highlight,
              }}
            />
          </>
        );
      }
    } else {
      return `All ${type}`;
    }
  };

  return (
    <Stack flexDirection="row">
      <FilterMenu
        id="region-menu"
        anchorEl={regionMenuAnchorEl}
        value={currentRegionOptions}
        options={regionOptions.map((option) => ({
          value: option,
          name: option,
        }))}
        label={getFilterLabel(filters.region, 'Regions')}
        clearable
        multiple
        onClick={(e) => setRegionMenuAnchorEl(e.currentTarget)}
        onClose={() => setRegionMenuAnchorEl(null)}
        onSelect={(option) => handleRegionChange(option)}
        onClear={handleClearRegionFilters}
        onApply={handleApplyRegionFilters}
        onCancel={handleCancelRegionFilters}
      />
      <FilterMenu
        id="market-menu"
        anchorEl={marketMenuAnchorEl}
        value={currentMarketOptions}
        options={marketOptions.map((option) => ({
          value: option,
          name: option,
        }))}
        label={getFilterLabel(filters.market, 'Markets')}
        clearable
        multiple
        searchable
        applyOnEnter
        onClick={(e) => setMarketMenuAnchorEl(e.currentTarget)}
        onClose={() => setMarketMenuAnchorEl(null)}
        onSelect={(option) => handleMarketChange(option)}
        onClear={handleClearMarketFilters}
        onApply={handleApplyMarketFilters}
        onCancel={handleCancelMarketFilters}
      />
      <FilterMenu
        id="filter-trades"
        anchorEl={tradeMenuAnchorEl}
        value={currentTradeOptions}
        searchable
        multiple
        clearable
        options={tradeOptions}
        label={
          <>
            Trade
            <Chip
              label={currentTradeOptions ? currentTradeOptions.length : 'Any'}
              size="small"
              variant="outlined"
              sx={{
                ml: 1,
                backgroundColor: currentTradeOptions
                  ? theme.palette.background.highlight
                  : undefined,
              }}
            />
          </>
        }
        onClick={(e) => setTradeMenuAnchorEl(e.currentTarget)}
        onClose={() => setTradeMenuAnchorEl(null)}
        onSelect={(option) => handleTradeChange(option)}
        onClear={handleClearTradeFilters}
        onApply={handleApplyTradeFilters}
        onCancel={handleCancelTradeFilters}
      />
      <Stack direction="row" justifyContent="flex-start" maxWidth="16rem">
        <Button
          onClick={() => handleDeclinedChange(!workspaceFilters.declined)}
          sx={{
            color: 'white',
            fontSize: '1rem',
            textTransform: 'none',
            pr: '1rem',
            '&:hover': {
              backgroundColor: theme.palette.background.default,
            },
          }}>
          <Checkbox
            checked={workspaceFilters.declined ? true : false}
            sx={{
              mb: '0.2rem',
              color: 'white !important',
              '&.Mui-checked': {
                color: `${theme.palette.background.highlight} !important`,
              },
            }}
          />
          Hide &quot;Declined&quot; Status
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="flex-start" maxWidth="16rem">
        <Button
          onClick={() =>
            handleDuplicateChange(!workspaceFilters.mentionsDuplicate)
          }
          sx={{
            color: 'white',
            fontSize: '1rem',
            textTransform: 'none',
            pr: '1rem',
            '&:hover': {
              backgroundColor: theme.palette.background.default,
            },
          }}>
          <Checkbox
            checked={workspaceFilters.mentionsDuplicate ? true : false}
            sx={{
              mb: '0.2rem',
              color: 'white !important',
              '&.Mui-checked': {
                color: `${theme.palette.background.highlight} !important`,
              },
            }}
          />
          Hide &quot;Duplicate&quot; mentions
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="flex-start" maxWidth="16rem">
        <Button
          onClick={() =>
            handlePendingChange(workspaceFilters.isDuplicate !== null)
          }
          sx={{
            color: 'white',
            fontSize: '1rem',
            textTransform: 'none',
            pr: '1rem',
            '&:hover': {
              backgroundColor: theme.palette.background.default,
            },
          }}>
          <Checkbox
            checked={workspaceFilters.isDuplicate === null ? true : false}
            sx={{
              mb: '0.2rem',
              color: 'white !important',
              '&.Mui-checked': {
                color: `${theme.palette.background.highlight} !important`,
              },
            }}
          />
          Show only pending entries
        </Button>
      </Stack>
    </Stack>
  );
};

export default Filters;
