/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from 'react';
import Map from 'components/Map';
import Navbar from 'components/Navbar';
import SidePanel from 'components/SidePanel';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAggregateData,
  getGeojson,
  getHeatmapData,
  getWorkorderData,
  selectFilters,
  selectPanelFilters,
  selectAggregationLevel,
  selectSortParam,
  setRegion,
  setCallDate,
  clearFilters,
  takeOwnership,
  getLocations,
  getFilterOptions,
  clearState,
  setMarket,
  getBsiConfigParams,
  selectTeamView,
  setDateRange,
} from 'storage/app/duck';
import IStore from 'lib/redux/models';
import { IAppState, IAppStateDerived } from 'storage/app/models';
import { Stack, Alert, Button } from '@mui/material';
import { IAuthState } from 'storage/auth/models';
import {
  getTeamViews,
  getNewFeatures,
  getUserViews,
  logout,
  refreshToken,
} from 'storage/auth/duck';
import { getDefaultCallDate } from 'utils/utils';
import { ITutorialState } from 'storage/tutorial/models';
import Tutorial from 'components/Tutorial/Tutorial';
import { trackEvent } from 'storage/tracking/duck';
import { INotesState } from 'storage/notes/models';
import Spinner from 'components/Spinner/Spinner';
import { Interweave } from 'interweave';
import ServiceChannelIcon from '../../assets/service-channel-icon.png';
import NewFeature from 'components/NewFeature';
import PotentialDuplicateWorkspace from 'components/PotentialDuplicateWorkspace';
import { IPotentialDuplicatesState } from 'storage/potentialDuplicates/models';

const Home: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector<IStore, IAppState['filters']>(selectFilters);
  const panelFilters = useSelector<IStore, IAppState['panelFilters']>(
    selectPanelFilters,
  );
  const sortParam = useSelector<IStore, IAppStateDerived['sortParam']>(
    selectSortParam,
  );
  const aggregationLevel = useSelector<
    IStore,
    IAppStateDerived['aggregationLevel']
  >(selectAggregationLevel);
  const {
    user,
    error: authError,
    teamViews,
  } = useSelector<IStore, IAuthState>((store) => store.auth);
  const { metadata, error, warning, loadingMetadata, regionMarkets } =
    useSelector<IStore, IAppState>((store) => store.app);
  const { error: notesError } = useSelector<IStore, INotesState>(
    (store) => store.notes,
  );
  const { running, steps, stepIndex } = useSelector<IStore, ITutorialState>(
    (state) => state.tutorial,
  );
  const isMapLoaded = useSelector<IStore, IAppState['isMapLoaded']>(
    (state) => state.app.isMapLoaded,
  );
  const isWorkspaceOpen = useSelector<
    IStore,
    IPotentialDuplicatesState['isWorkspaceOpen']
  >((state) => state.potentialDuplicates.isWorkspaceOpen);
  const [errors, setErrors] = useState<
    { id: number; message: string; data?: any; timeout: number }[] | undefined
  >(undefined);
  const errorsRef = useRef<
    { id: number; message: string; timeout: number }[] | undefined
  >(undefined);
  const [warnings, setWarnings] = useState<
    | { id: number; message: string; data?: any; timeout: number | undefined }[]
    | undefined
  >(undefined);
  const warningsRef = useRef<
    { id: number; message: string; timeout: number | undefined }[] | undefined
  >(undefined);
  const timeoutRef = useRef<number | null>(null);
  const overviewWidth = document.querySelector(
    '#aggregates-overview',
  )?.clientWidth;
  const accessExpTimeRef = useRef<number | undefined>(undefined);
  const accessTokenTimeoutRef = useRef<number | undefined>(undefined);
  const refreshExpTimeRef = useRef<number | undefined>(undefined);
  const refreshTokenTimeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    dispatch(getNewFeatures());
    dispatch(getLocations());
    dispatch(getGeojson());
    dispatch(getUserViews());
    dispatch(getFilterOptions());
    dispatch(getBsiConfigParams());
  }, []);

  useEffect(() => {
    if (isMapLoaded) {
      dispatch(getTeamViews());
    }
  }, [isMapLoaded]);

  useEffect(() => {
    const defaultTeamView = teamViews.find((view) => view.is_default);
    if (defaultTeamView) {
      dispatch(selectTeamView(defaultTeamView.state));
      if (
        defaultTeamView.state.dateRange &&
        defaultTeamView.state.dateRange.value !== 'custom'
      ) {
        dispatch(setDateRange(defaultTeamView.state.dateRange));
      } else {
        dispatch(setDateRange({ name: 'Custom', value: 'custom' }));
        dispatch(setCallDate(defaultTeamView.state.callDate));
      }
    }
  }, [teamViews.length]);

  useEffect(() => {
    if (!loadingMetadata && !filters.callDate.start) {
      const defaultCallDate = getDefaultCallDate(
        metadata.bsi_updated_date,
        false,
      );
      dispatch(setCallDate(defaultCallDate));
    }
  }, [metadata.bsi_updated_date, loadingMetadata, filters.callDate.start]);

  useEffect(() => {
    if (
      !window.location.search &&
      regionMarkets &&
      !!Object.keys(regionMarkets).length
    ) {
      if (user?.default_region !== filters.region) {
        dispatch(setRegion(user?.default_region));
      }
      if (user?.default_market !== filters.market) {
        dispatch(setMarket(user?.default_market));
      }
    }
  }, [user?.default_region, user?.default_market, regionMarkets]);

  /*
   * Handle error messages timeout
   */
  useEffect(() => {
    const previousErrors = errorsRef.current ? errorsRef.current : [];
    const id = new Date().getTime();
    if (error) {
      const maxTimeout = Math.max(error.message?.length * 90, 5000);
      const newError = { id: id, timeout: maxTimeout, ...error };
      // Prevent duplicate error messages
      if (!previousErrors.find((e) => e.message === newError.message)) {
        errorsRef.current = [...previousErrors, newError];
      }
      setErrors(errorsRef.current);
      window.setTimeout(() => {
        clearError(newError.id);
      }, maxTimeout);
      dispatch(
        trackEvent({
          namespace: 'Display error',
          predicate: 'Errors',
          value: id.toString(),
          payload: { message: error.message, timeout: maxTimeout },
        }),
      );
    }
  }, [error]);

  /*
   * Handle notes error messages timeout
   */
  useEffect(() => {
    const previousErrors = errorsRef.current ? errorsRef.current : [];
    const id = new Date().getTime();
    if (notesError) {
      const maxTimeout = Math.max(notesError.message.length * 90, 5000);
      const newError = { id: id, timeout: maxTimeout, ...notesError };
      errorsRef.current = [...previousErrors, newError];
      setErrors(errorsRef.current);
      window.setTimeout(() => {
        clearError(newError.id);
      }, maxTimeout);
      dispatch(
        trackEvent({
          namespace: 'Display error',
          predicate: 'Errors',
          value: id.toString(),
          payload: { message: notesError.message, timeout: maxTimeout },
        }),
      );
    }
  }, [notesError]);

  /*
   * Handle auth error messages timeout
   */
  useEffect(() => {
    const previousErrors = errorsRef.current ? errorsRef.current : [];
    const id = new Date().getTime();
    if (authError) {
      const maxTimeout = Math.max(authError.message.length * 90, 5000);
      const newError = { id: id, timeout: maxTimeout, ...authError };
      errorsRef.current = [...previousErrors, newError];
      setErrors(errorsRef.current);
      window.setTimeout(() => {
        clearError(newError.id);
      }, maxTimeout);
      dispatch(
        trackEvent({
          namespace: 'Display error',
          predicate: 'Errors',
          value: id.toString(),
          payload: { message: authError.message, timeout: maxTimeout },
        }),
      );
    }
  }, [notesError]);

  const clearError = (id: number) => {
    const error = errorsRef.current?.find((e) => e.id === id);
    errorsRef.current = errorsRef.current?.filter((e) => e.id !== id);
    setErrors(errorsRef.current);
    if (error) {
      if (
        error.message?.includes('Authentication credentials were not provided.')
      ) {
        window.location.href = '/login/' + window.location.search;
      }
      dispatch(
        trackEvent({
          namespace: 'Clear error',
          predicate: 'Errors',
          value: id.toString(),
        }),
      );
    }
  };

  /*
   * Handle warning messages timeout
   */
  useEffect(() => {
    const previousWarnings = warningsRef.current ? warningsRef.current : [];
    const id = new Date().getTime();
    if (warning) {
      const maxTimeout = Math.max(warning.message.length * 90, 5000);
      const newWarning = { id: id, timeout: maxTimeout, ...warning };
      warningsRef.current = [...previousWarnings, newWarning];
      setWarnings(warningsRef.current);
      if (newWarning.timeout) {
        window.setTimeout(() => {
          clearWarning(newWarning.id);
        }, maxTimeout);
      }
      dispatch(
        trackEvent({
          namespace: 'Display warning',
          predicate: 'Warnings',
          value: id.toString(),
          payload: {
            message: warning.message,
            ...(maxTimeout ? { timeout: maxTimeout } : {}),
          },
        }),
      );
    }
  }, [warning]);

  const clearWarning = (id: number) => {
    const warning = warningsRef.current?.find((w) => w.id === id);
    warningsRef.current = warningsRef.current?.filter((w) => w.id !== id);
    setWarnings(warningsRef.current);
    if (warning) {
      dispatch(
        trackEvent({
          namespace: 'Clear warning',
          predicate: 'Warnings',
          value: id.toString(),
        }),
      );
    }
  };

  useEffect(() => {
    if (user?.exp && user.iat) {
      accessExpTimeRef.current =
        (user.exp - new Date().getTime() / 1000 - 5) * 1000;
    }
    handleAccessToken();
    return () => {
      window.clearTimeout(accessTokenTimeoutRef.current);
    };
  }, [user?.exp, user?.iat]);

  const handleAccessToken = () => {
    if (user && accessExpTimeRef.current) {
      accessTokenTimeoutRef.current = window.setTimeout(() => {
        dispatch(refreshToken());
      }, accessExpTimeRef.current);
    }
  };

  useEffect(() => {
    if (user?.refresh_exp) {
      refreshExpTimeRef.current =
        (user.refresh_exp - new Date().getTime() / 1000 - 10) * 1000;
    }
    handleRefreshToken();
    return () => {
      window.clearTimeout(refreshTokenTimeoutRef.current);
    };
  }, [user?.refresh_exp]);

  const handleRefreshToken = () => {
    if (user && refreshExpTimeRef.current) {
      refreshTokenTimeoutRef.current = window.setTimeout(() => {
        window.clearTimeout(accessTokenTimeoutRef.current);
        dispatch(logout({ isTimeout: true }));
        dispatch(clearState());
      }, refreshExpTimeRef.current);
    }
  };

  const handleClearFilters = () => {
    dispatch(clearFilters({ keepGeoFilter: true }));
  };

  useEffect(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = window.setTimeout(() => {
      if (metadata.loaded) {
        dispatch(getAggregateData({ filters, aggregationLevel }));
        dispatch(getWorkorderData({ filters, panelFilters, sortParam }));
        dispatch(getHeatmapData({ filters }));
      }
      timeoutRef.current = null;
    }, 200);
  }, [
    filters.region,
    filters.market,
    filters.club,
    filters.status,
    filters.trades,
    filters.callDate,
    filters.workorders,
    filters.providers,
  ]);

  useEffect(() => {
    if (!timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        dispatch(getWorkorderData({ filters, panelFilters, sortParam }));
        timeoutRef.current = null;
      }, 200);
    }
  }, [panelFilters.working_on_it]);

  useEffect(() => {
    if (!timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        dispatch(getWorkorderData({ filters, panelFilters, sortParam }));
        timeoutRef.current = null;
      }, 200);
    }
  }, [sortParam]);

  useEffect(() => {
    if (!timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        dispatch(getAggregateData({ filters, aggregationLevel }));
        dispatch(getWorkorderData({ filters, panelFilters, sortParam }));
        timeoutRef.current = null;
      }, 200);
    }
  }, [filters.importance, filters.urgency]);

  const handleTakeOwnership = (id: number) => {
    dispatch(takeOwnership({ workorderId: id }));
  };

  return (
    <Stack
      className="homepage"
      component="section"
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}>
      <Spinner isVisible={loadingMetadata && !metadata.loaded} />
      <Navbar />
      <Stack id="alerts-overview"></Stack>
      <Stack
        direction="row"
        component="main"
        flex={1}
        sx={{ overflow: 'hidden', position: 'relative' }}>
        <Tutorial steps={steps} stepIndex={stepIndex} running={running} />
        {isMapLoaded && user?.new_features && (
          <NewFeature
            steps={user?.new_features.sort((a, b) =>
              a.id && b.id ? a.id - b.id : 0,
            )}
          />
        )}
        {isWorkspaceOpen && <PotentialDuplicateWorkspace />}
        {!isWorkspaceOpen && <SidePanel />}
        <Map />
        <Stack
          id="stacked-alerts"
          sx={{
            position: 'absolute',
            bottom: '1rem',
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 2000,
          }}>
          {errors &&
            errors.map((error) => (
              <Alert
                variant="filled"
                key={error.id}
                severity="error"
                sx={{ marginTop: '0.5rem', position: 'relative' }}
                onClick={() => clearError(error.id)}>
                <Interweave content={error.message} />
                <div
                  className="alert-timeout"
                  style={{ animationDuration: `${error.timeout}ms` }}
                />
              </Alert>
            ))}
          {warnings &&
            warnings.map((warning) => (
              <Alert
                variant="filled"
                key={warning.id}
                severity="warning"
                sx={{ marginTop: '0.5rem', position: 'relative' }}
                onClick={() => clearWarning(warning.id)}>
                <Interweave content={warning.message} />
                {warning.message.includes(
                  "The location you have selected doesn't have any active work orders",
                ) && (
                  <Button variant="contained" onClick={handleClearFilters}>
                    Clear filters
                  </Button>
                )}
                {warning.message.includes('Only') && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleTakeOwnership(warning.data.workorderId)
                    }>
                    Take ownership
                  </Button>
                )}
                {warning.message.includes(
                  'Work order not found in Battleship database. However, it was found in',
                ) && (
                  <Button
                    variant="contained"
                    href={`${metadata.sc_portal_url}/sc/wo/Workorders/index?id=${warning.data.workorderId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      padding: '0 0.5rem',
                      minWidth: 'unset',
                      marginLeft: '0.5rem',
                    }}>
                    Service Channel
                    <img
                      src={ServiceChannelIcon}
                      width="18"
                      height="18"
                      alt="Service Channel"
                      style={{ marginLeft: '0.25rem' }}
                    />
                  </Button>
                )}
                <div
                  className="alert-timeout"
                  style={{ animationDuration: `${warning.timeout}ms` }}
                />
              </Alert>
            ))}
        </Stack>
        {metadata.broadcast_message && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              top: '4rem',
              left: '33rem',
              right: overviewWidth ? `calc(${overviewWidth}px + 2rem)` : '1rem',
              position: 'absolute',
            }}>
            <Alert
              variant="filled"
              severity="warning"
              sx={{ cursor: 'unset !important' }}>
              {metadata.broadcast_message}
            </Alert>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Home;
