import React, { FC, useEffect, useState, useRef, KeyboardEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState } from 'storage/auth/models';
import { Stack, Card, CardContent, CardActions, Button, TextField, Typography, CircularProgress, Alert } from '@mui/material';
import { login } from 'storage/auth/duck';
import LoginBackground from '../../assets/login-bg.jpg';
import theme from 'theme';
import { IAppState } from 'storage/app/models';
import ByMakepath from 'components/Map/ByMakepath';


const Login: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const metadata = useSelector<IStore, IAppState['metadata']>((state) => state.app.metadata);
  const error = useSelector<IStore, IAuthState['error']>((state) => state.auth.error);
  const loading = useSelector<IStore, IAuthState['loading']>((state) => state.auth.loading);
  const loggedIn = useSelector<IStore, IAuthState['loggedIn']>((state) => state.auth.loggedIn);
  const user = useSelector<IStore, IAuthState['user']>((state) => state.auth.user);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const errorTimeoutRef = useRef<number | undefined>(undefined);


  /*
  * If the user is logged in, redirect them to the home page
  */
  useEffect(() => {
    if (user && loggedIn) {
      history.push('/', { state: { search: history.location.search } });
    }
  }, [user, loggedIn])

  /*
  * Handle error message timeout
  */
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      if (errorTimeoutRef.current) {
        window.clearTimeout(errorTimeoutRef.current);
        errorTimeoutRef.current = undefined;
      }
      errorTimeoutRef.current = window.setTimeout(() => {
        setErrorMessage(undefined);
        errorTimeoutRef.current = undefined;
      }, 5000);
    }
  }, [error, loading])

  const handleLogin = () => {
    const lowercaseEmail = email.toLowerCase();
    setEmail(lowercaseEmail);
    dispatch(login({ email: lowercaseEmail, password }));
  }

  const handleKeyDownLogin = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && email && password) {
      handleLogin()
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (
    <Stack
      className="homepage"
      component="section"
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <Stack
        direction="row"
        component="main"
        flex={1}
        sx={{ overflow: 'hidden', position: 'relative' }}
      >
        <div style={{ position: 'absolute', zIndex: -1, width: '100%', height: '100%', opacity: 1, backgroundImage: `url(${LoginBackground})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
        <Stack justifyContent={"center"} alignItems={"center"} flex={1}>
          <Card elevation={7} sx={{ padding: "2rem", backgroundColor: theme.palette.login.paper, borderRadius: '0.5rem' }}>
            <CardContent>
              <Stack gap={2}>
                <Typography variant="h1" fontWeight={700} sx={{ textAlign: "center", fontSize: "2.5rem" }}>
                  Battleship
                </Typography>
                <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.25rem", marginBottom: "1rem" }}>
                  Welcome, Captain!
                </Typography>
                <TextField
                  label="Email"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  onSubmit={handleLogin}
                  disabled={loading}
                  onKeyDown={e => handleKeyDownLogin(e)}
                  fullWidth
                  autoFocus
                />
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onSubmit={handleLogin}
                  disabled={loading}
                  onKeyDown={e => handleKeyDownLogin(e)}
                  fullWidth
                />
              </Stack>
            </CardContent>
            <CardActions sx={{ display: 'flex', flexDirection: 'column', padding: '0 1rem' }}>
              <Button variant="contained" sx={{ marginBottom: '1rem', width: '100%' }} onClick={handleLogin}>
                Login {loading &&
                  <CircularProgress sx={{
                    color: theme.palette.background.default,
                    height: "1rem !important",
                    width: "1rem !important",
                    marginLeft: "1rem"
                  }} />
                }
              </Button>
              <Link to="/reset-password/" style={{ color: theme.palette.text.primary }}>Forgot password</Link>
            </CardActions>
          </Card>
        </Stack>
        <Stack flexDirection="row" alignItems="flex-end" sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}>
          <ByMakepath boxStyles={{ padding: 0, backgroundColor: 'transparent', marginRight: '1rem' }} />
          {metadata.version &&
            <Typography  >{metadata.version === 'latest' ? metadata.version : `v${metadata.version}`}</Typography>
          }
        </Stack>
        <Stack id="stacked-alerts" sx={{ position: 'absolute', bottom: "1rem", left: "50%", transform: "translate(-50%)", zIndex: 2000 }}>
          {errorMessage &&
            <Alert
              variant="filled"
              severity='error'
              sx={{ marginTop: "0.5rem", position: 'relative', }}
              onClick={() => setErrorMessage(undefined)}
            >
              {errorMessage}
            </Alert>
          }
          {metadata.broadcast_message &&
            <Alert variant="filled" severity='warning' sx={{ marginTop: "0.5rem", position: 'relative', cursor: 'unset !important' }} >
              {metadata.broadcast_message}
            </Alert>
          }
        </Stack>
      </Stack>
    </Stack >
  );
};

export default Login;
